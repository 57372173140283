import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Constants } from 'src/app/util/constants';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { PopUpPage } from 'src/app/modules/common/modals/pop-up/pop-up.page';
import { State } from 'src/app/modules/auth/models/auth.types';
import shuffleArray from '../../../../util/shuffleArray';

@Component({
  selector: 'app-que-select',
  templateUrl: './que-select.component.html',
  styleUrls: ['./que-select.component.scss']
})
export class QueSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() question: any = null;
  @Output() answerSelectEvent = new EventEmitter();
  @Input() correctAnswerID: any = [];
  @Input() correct: any = 'unset';

  state: State;
  answers: any = {};
  correctAnswerCount = 0;
  disableField = false;

  constructor(
    private readonly questions: QuestionsService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly authStateService: AuthStateService,
    private readonly audio: AudioService
  ) {}

  ngOnInit() {
    this.setup();
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.correct = 'unset';
    const words = shuffleArray(this.question.words);
    // eslint-disable-next-line id-blacklist
    this.correctAnswerCount = words.filter((number: { points: number }) => number.points > 0).length;
    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');
    this.answers = {};
    this.question.words_left = words.slice(0, 3);
    this.question.words_right = words.slice(3);
  }

  checkSelectLimit(word) {
    this.answers[word.id] = !this.answers[word.id] ? true : false;
    const arr = [];
    for (const key in this.answers) {
      if (this.answers.hasOwnProperty(key) && this.answers[key] === true) {
        arr.push(key);
      }
    }
    this.disableField = arr.length === this.correctAnswerCount ? true : false;
    this.answerSelectEvent.emit(this.answers);
  }

  async nextQuestion() {
    let answered = 0;
    let points = 0;
    let answerRevealTime = Constants.delayTime.correctAnswerDelay;
    for (const a in this.answers) {
      if (this.answers[a]) {
        for (const w of this.question.words) {
          if (a === w.id.toString()) {
            points += w.points;
            break;
          }
        }

        answered++;
      }
    }

    if (answered) {
      const response = await this.questions.answerQuestion(points);

      this.correctAnswerID = response.correctWordID;
      this.correct = response.correct;

      // Play sounds
      if (this.correct) {
        this.audio.play('correct');
      } else {
        this.audio.play('incorrect');
        answerRevealTime = Constants.delayTime.inCorrectAnswerDelay;
      }

      // Get all the correct words (id) and assign correctWord css class
      if (!this.correct) {
        for (const correctID of this.correctAnswerID) {
          if (correctID) {
            const element = document.getElementById(correctID);
            element.className = 'correctWord';
          }
        }
      }

      setTimeout(() => {
        if (response.next === 8) {
          this.setup();
        } else {
          this.audio.play('click');
          this.router.navigate(['/child/question/' + response.next]);
        }
      }, answerRevealTime); // 2 seconds
    } else {
      const err = await this.modalCtrl.create({
        component: PopUpPage,
        showBackdrop: false,
        componentProps: {
          title: 'Notice',
          text: 'Please select an answer'
        }
      });

      await err.present();
    }
  }

  exitGame() {
    if (this.state.user.first) {
      this.authStateService.logout();
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }

  exitImpersonatedParent() {
    if (this.state.user.first) {
      this.authStateService.signOutImpersonate(this.state);
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }
}
