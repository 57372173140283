<ion-content class="content-bg-color">
  <div class="center-all column selectt">

    <p class="instruction">
      Select {{correctAnswerCount === 1 ? '' : correctAnswerCount }} {{correctAnswerCount === 1 ? 'the synonym' : 'synonyms'}}
      for the focus word</p>
    <ion-grid>
      <ion-row>
        <ion-col>

          <div class="question-image">
            <img [src]="(question.image_url) ? question.image_url : '/assets/account-icons/tree.svg'">
          </div>

          <p class="focus-word">"{{ question.focus }}"</p>

        </ion-col>
        <ion-col>

          <div class="word-list" [class.disableField]="disableField">

            <div *ngFor="let word of question.words_left" class="wl-item" [class.filled]="answers[word.id]" [class.unfilled]="!answers[word.id]">
              <div id="{{word.id}}" [class.correctWord]="!correct && correctAnswerID?.includes(word.id)" class="word" (click)="checkSelectLimit(word)">{{ word.word }}</div>
            </div>

          </div>

        </ion-col>
        <ion-col>

          <div class="word-list" [class.disableField]="disableField">

            <div *ngFor="let word of question.words_right; index as i" class="wl-item" [class.filled]="answers[word.id]" [class.unfilled]="!answers[word.id]">
              <div id="{{word.id}}" [class.correctWord]="!correct && correctAnswerID?.includes(word.id)" class="word" (click)="checkSelectLimit(word)">{{ word.word }}</div>
            </div>

          </div>

        </ion-col>
      </ion-row>
    </ion-grid>

  </div>
</ion-content>
