import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PrivateApiService } from 'src/app/modules/api/private-api.service';
import { filter, pairwise } from 'rxjs/operators';
import { LearnersService } from './learners.service';

export interface WondeStatus {
  id: string;
  domain: string;
  enabled: boolean;
  groups_total: number;
  groups_enabled: number;
  learners_total: number;
  status: string;
  sync_completed_at: string;
  sync_requested_at: string;
  sync_started_at: string;
  sync_duration: number;
  sync_status: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const POLL_INTERVAL_LONG = 60000; // 60 seconds
// eslint-disable-next-line @typescript-eslint/naming-convention
const POLL_INTERVAL_SHORT = 5000; // 5 seconds

@Injectable({ providedIn: 'root' })
export class WondeService {
  ready: boolean;
  readonly busy: Observable<boolean>;
  readonly enabled: Observable<boolean>;
  readonly pending: Observable<boolean>;
  readonly status: Observable<WondeStatus>;
  private busy$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  private enabled$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  private pending$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  private status$: BehaviorSubject<WondeStatus> = new BehaviorSubject({} as WondeStatus);
  private pollInterval = POLL_INTERVAL_SHORT;

  constructor(private readonly api: PrivateApiService, private readonly learners: LearnersService) {
    console.log('WondeService: Init');

    this.busy = this.busy$.asObservable();
    this.enabled = this.enabled$.asObservable();
    this.pending = this.pending$.asObservable();
    this.status = this.status$.asObservable();

    this.busy$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => prev && !curr)
      )
      .subscribe(() => {
        this.learners.fetch();
      });

    this.fetch();
  }

  public fetch(): void {
    this.api.getWondeStatus().subscribe((res) => {
      const status = res.data;
      this.status$.next(status);
      this.enabled$.next(status && status.status === 'enabled');
      this.pending$.next(status && status.status === 'pending');

      const busy = status && ['in_progress', 'queued'].indexOf(status.sync_status) >= 0;
      this.busy$.next(busy);
      this.pollInterval = busy ? POLL_INTERVAL_SHORT : POLL_INTERVAL_LONG;

      if (!this.ready) {
        this.ready = true;
      }

      console.log('WondeService: Updated');

      setTimeout(() => {
        console.log(`WondeService: Fetching every ${this.pollInterval / 1000} seconds`);
        this.fetch();
      }, this.pollInterval);
    });
  }
}
