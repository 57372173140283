import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { DemoService } from 'src/app/teacher/services/demo.service';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {
  private demoActive = false;

  constructor(private readonly demo: DemoService) {
    this.demo.active$.subscribe((active) => (this.demoActive = active));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(
        req.clone({
          setHeaders: {
            'x-billing-platform': Capacitor.getPlatform(),
            'x-chatterstars-demo': this.demoActive ? '1' : '0'
          }
        })
      )
      .pipe(
        tap((event) => {
          // do something
        })
      );
  }
}
