<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>
      <img src="/assets/branding/star.svg">
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="container">
    <h1>Ask your parents</h1>
    <h3 *ngIf="type === '0'">To make sure your parent/carer is happy for you to contact support, please ask them to answer this question:</h3>
    <h3 *ngIf="type === '1'">To make sure your parent/carer is happy to purchase this app, please ask them to answer this question:</h3>
    <div class="question">
      <span>{{ a }}&nbsp;</span>
      <span> {{ operatorString }}&nbsp; </span>
      <span>{{ b }}</span>
    </div>

    <div class="answers">
      <button class="mat-flat-button mat-accent" mat-flat-button color="accent" (click)="submit(shuffledAnswers[0])">{{shuffledAnswers[0]}}</button>
      <button class="mat-flat-button mat-accent" mat-flat-button color="accent" (click)="submit(shuffledAnswers[1])">{{shuffledAnswers[1]}}</button>
      <button class="mat-flat-button mat-accent" mat-flat-button color="accent" (click)="submit(shuffledAnswers[2])">{{shuffledAnswers[2]}}</button>
    </div>
  </div>
</ion-content>
