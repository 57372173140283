import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Gesture, GestureController, LoadingController, ModalController } from '@ionic/angular';

import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'app-que-slide',
  templateUrl: './que-slide.component.html',
  styleUrls: ['./que-slide.component.scss']
})
export class QueSlideComponent {
  @ViewChild('list') list: ElementRef;

  @Input() question: any = null;
  @Output() selectedEvent = new EventEmitter();

  selected: any = {
    id: 0,
    word: '',
    points: 'none'
  };

  words: any = [];

  slider: any = {
    bounds: 0,
    margin_left: 0,
    children: []
  };

  correct: any = 'unset';
  correctWord = '';
  swipeXCoords: number;

  constructor(
    private readonly questions: QuestionsService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService,
    private gestureCtrl: GestureController
  ) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    const gesture: Gesture = this.gestureCtrl.create(
      {
        el: this.list.nativeElement,
        threshold: 15,
        gestureName: 'horizontalSwipe',
        direction: 'x',
        onMove: (ev) => this.swipe(ev, 'onMove'),
        onEnd: (ev) => this.swipe(ev, 'onEnd')
      },
      true
    );

    gesture.enable();

    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.correct = 'unset';

    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    this.selected = this.question.words.length;

    this.words = [];
    const includeWords = this.shuffle(this.question.words);

    for (let c = 1; c <= 2; c++) {
      // eslint-disable-next-line guard-for-in
      for (const i in includeWords) {
        this.words.push(this.question.words[i]);
      }
    }

    this.slider = {
      bounds: 0,
      margin_left: 0,
      children: []
    };

    this.slider.children = this.list.nativeElement.children;

    setTimeout(() => {
      this.slider.bounds = this.list.nativeElement.clientWidth / 2;

      this.getMarginLeft(this.selected);
    }, 100);
  }

  swipe(event, type) {
    if (type === 'onMove') {
      this.swipeXCoords = event.deltaX;
    }
    if (type === 'onEnd') {
      if (event.deltaX < -100) {
        this.select(1);
      }
      if (event.deltaX > 100) {
        this.select(0);
      }
    }
  }

  getMarginLeft(index) {
    let margin_left = 0;
    let i = 0;
    for (const child of this.slider.children) {
      if (i <= index) {
        const width = child.offsetWidth;
        margin_left -= i < index ? parseInt(width, 10) : parseInt(width, 10) / 2;
        i++;
      } else {
        break;
      }
    }

    this.slider.margin_left = this.slider.bounds + margin_left;
  }

  select(dir) {
    if (dir) {
      if (this.selected > 0) {
        this.selected--;
        this.selectNumber(this.selected);
      }
    } else {
      if (this.selected < this.words.length - 1) {
        this.selected++;
        this.selectNumber(this.selected);
      }
    }
  }

  selectNumber(num) {
    this.selected = num;
    this.selectedEvent.emit((this.selected % (this.words.length / 2)).toString());
    this.getMarginLeft(this.selected);
  }

  shuffle(array) {
    let currentIndex = array.length;
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  exitGame() {
    this.router.navigate(['child/dashboard']);
  }

  exitImpersonatedParent() {
    this.router.navigate(['child/dashboard']);
  }
}
