<ion-content scrollY="false">
  <div class="alert-wrapper">
    <div class="alert-container">
      <img src="/assets/branding/star.svg"/>

      <h1 *ngIf="title" [innerHTML]="title"></h1>
      <p *ngIf="message" [innerHTML]="message"></p>

      <ion-list *ngIf="rows">
        <ion-item-group *ngFor="let row of rows">
          <ion-item-divider>
            <ion-label>{{ row.label }}</ion-label>
          </ion-item-divider>
          <ion-item *ngFor="let message of row.messages">
            <ion-label>{{ message }}</ion-label>
          </ion-item>
        </ion-item-group>
      </ion-list>

      <button mat-flat-button color="primary" (click)="troubleshoot(true)">Close</button>
    </div>
  </div>
</ion-content>
