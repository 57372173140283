<ion-app>
  <ion-menu contentId="content1" side="end" type="overlay" *ngIf="user && user.type !== 'child'">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <img src="/assets/branding/star.svg">
        </ion-title>
        <ion-buttons slot="end">
          <ion-menu-button>
            <ion-button [routerLink]="getUrl()" *ngIf="user && user.type === 'teacher'">
              <ion-icon src="assets/misc-icons/add-users.svg"></ion-icon>
            </ion-button>
            <img src="/assets/misc-icons/menu.svg">
          </ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="main-content" class="center-all column">
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" detail="false">
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-menu-toggle>
          <button mat-flat-button color="accent" (click)="showSignOut()">Sign Out</button>
        </ion-menu-toggle>
      </div>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="content1" main></ion-router-outlet>
</ion-app>