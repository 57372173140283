import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ModalController, Platform } from '@ionic/angular';
import { ParentalGateComponent } from '../common/modals/parental-gate/parental-gate.component';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ParentalGateGuard implements CanActivate {
  constructor(private readonly modalCtrl: ModalController, private readonly platform: Platform) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (this.platform.is('ios')) {
      return fromPromise(this.openModal()).pipe(
        tap((answer) => console.log(answer)),
        map((answer) => !!answer.data)
      );
    } else {
      return of(true);
    }
  }

  async openModal(): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: ParentalGateComponent,
      showBackdrop: false
    });
    await modal.present();
    return await modal.onWillDismiss();
  }
}
