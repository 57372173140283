import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

interface Row {
  label: string;
  messages: string[];
}

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.page.html',
  styleUrls: ['./pop-up.page.scss']
})
export class PopUpPage {
  title = '';
  message = '';
  rows: Row[];

  constructor(public modalCtrl: ModalController, public navParams: NavParams) {
    this.title = navParams.get('title') || '';
    this.message = navParams.get('text') || '';
    this.rows = navParams.get('rows') || [];
  }

  ionModalWillPresent() {
    this.title = this.navParams.get('title') || '';
    this.message = this.navParams.get('text') || '';
    this.rows = this.navParams.get('rows') || [];
  }

  troubleshoot(choice) {
    this.modalCtrl.dismiss({ dismissed: choice });
  }
}
