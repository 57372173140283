<div *ngIf="active" class="container">
    <div class="content">
        <h1>This is example data</h1>

        <p>
            Once you're ready to add your own pupils, click "Show live data"
        </p>

        <p>
            You can enable example data at any time in your profile.
        </p>

        <ion-button expand="block" (click)="disable()">
            Show live data
        </ion-button>
    </div>
</div>