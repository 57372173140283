<ion-content>
  <div class="full-width">
    <ng-container *ngFor="let item of questions.set">
      <div class="fit-to-div" *ngIf="shouldShowContainer(item)">
        <app-que-roll *ngIf="shouldShowQuestion('roll')" [question]="question" (selectedEvent)="selectedEvent($event)"></app-que-roll>
        <app-que-arrows *ngIf="shouldShowQuestion('arrows')" [question]="question" [selectedTarget]="selectedTargetArrow" (arrowEvent)="arrowEvent($event)"></app-que-arrows>
        <app-que-brick-wall *ngIf="shouldShowQuestion('brick-wall')" [question]="question" (selectedEvent)="selectedEvent($event)" (pointsEvent)="pointsEvent($event)"></app-que-brick-wall>
        <app-que-drag *ngIf="shouldShowQuestion('drag')" [question]="question" [correct]="correct" [correctAnswerID]="correctAnswerID" (answersDragEvent)="answersDragEvent($event)" [hasCompleted]="hasCompleted"></app-que-drag>
        <app-que-select *ngIf="shouldShowQuestion('select')" [question]="question" [correctAnswerID]="correctAnswerID" [correct]="correct" (answerSelectEvent)="answerSelectEvent($event)"></app-que-select>
        <app-que-slide *ngIf="shouldShowQuestion('slide')" [question]="question" (selectedEvent)="selectedEvent($event)"></app-que-slide>
        <app-que-sort *ngIf="shouldShowQuestion('sort')" [question]="question" [correctOrder]="correctOrder" [correct]="correct" (answerEvent)="answerEvent($event)"></app-que-sort>
        <app-que-spin *ngIf="shouldShowQuestion('spin')" [question]="question" (selectedEvent)="selectedEvent($event)" (firstSpin)="firstSpin($event)"></app-que-spin>
        <app-que-anagram *ngIf="shouldShowQuestion('anagram')" [question]="question" (answerEvent)="answerEvent($event)" (processAnswerEvent)="nextQuestion()"></app-que-anagram>
        <app-que-oddoneout *ngIf="shouldShowQuestion('oddoneout')" [question]="question" (answerEvent)="answerEvent($event)" ></app-que-oddoneout>
        <app-que-cloze *ngIf="shouldShowQuestion('cloze')" spin [question]="question" [correct]="correct" [correctAnswerID]="correctAnswerID" (cloePuzzleEvent)="cloePuzzleEvent($event)"></app-que-cloze>
        <app-que-connections *ngIf="shouldShowQuestion('connection')" [question]="question" [isWrongAnswer]="isWrongAnswerConnection" (connectionEvent)="connectionEvent($event)" [hasCompleted]="hasCompleted"></app-que-connections>
      </div>
      <app-game-buttons [correct]="correct" [correctWord]="correctWord" [type]="type" (nextBtnClick)="nextQuestion()" (exitBtnClick)="exitImpersonatedParent()" [isVisible]="isGameButtonVisible"></app-game-buttons>
    </ng-container>
  </div>
</ion-content>
