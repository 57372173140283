<ion-content scrollY="true">
  <div class="main">
    <div class="results" *ngIf="state.lastResult">
      <img src="/assets/misc-icons/crown.svg" alt="Crown"/>
      <h1>Good work, {{ state.user.first_name }}</h1>
      <h3>You got <strong>{{ state.lastResult?.correct_answers }}</strong> out of
        <strong>{{ state.lastResult?.total_questions }}</strong> questions correct!</h3>
      <h3>{{ state.lastResult?.message ?? "" }}</h3>
      <div class="columns col-1-1 gap-x1">
        <div class="score">
          <img src="/assets/branding/star.svg" alt="Stars">
          <h3> +{{ state.lastResult?.stars }} Stars</h3>
        </div>
        <div class="score">
          <img src="/assets/icon/gem.svg" alt="Stars">
          <h3> {{ state.lastResult?.gems }} Gems</h3>
        </div>
      </div>
    </div>
    <div class="flex-spacer"></div>
    <div class="buttons" *ngIf="state.lastResult">
      <div class="columns col-1-1 gap-x1">
        <button class="mat-flat-button mat-secondary" mat-flat-button (click)="exit()">Exit</button>
        <button class="mat-flat-button mat-accent" mat-flat-button (click)="nextSet()">Puzzle Again</button>
      </div>
    </div>
  </div>
</ion-content>
