import bugsnag from './bugsnag';
import firebase from './firebase';
import stripeTest from './stripe.test';
import revenuecatStaging from './revenuecat.staging';

export const environment = {
  maintenance: false,
  production: true,
  title: 'ChatterStars',
  name: 'staging',
  baseUrl: 'https://api.staging.chatterstars.co.uk/',
  appUrl: 'https://staginguser.chatterstars.co.uk/',
  appId: '1',
  settings: {
    disableLoginChecks: false,
    enableMockData: false
  },
  hubspot: {
    enabled: true
  },
  stripe: stripeTest,
  firebase,
  bugsnag,
  revenuecat: revenuecatStaging
};
