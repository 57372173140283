import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import shuffleArray from '../../../../util/shuffleArray';

@Component({
  selector: 'app-que-sort',
  templateUrl: './que-sort.component.html',
  styleUrls: ['./que-sort.component.scss']
})
export class QueSortComponent implements OnDestroy, OnInit, AfterViewInit {
  @Input() question: any = null;
  @Output() answerEvent = new EventEmitter();
  @Input() correctOrder: string[] = new Array(4);
  @Input() correct: any = 'unset';

  state: State;
  subs = new Subscription();
  answers: any = [[], [], [], []];
  available_words: any = [];

  constructor(
    private readonly questions: QuestionsService,
    private readonly dragulaService: DragulaService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly authStateService: AuthStateService,
    private readonly audio: AudioService
  ) {}

  ngOnInit() {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
    this.setup();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidLeave() {
    this.subs.unsubscribe();
  }

  ionViewDidEnter() {
    this.answers = [[], [], [], []];

    this.subs = new Subscription();
    this.subs.add(
      this.dragulaService.drag('words').subscribe((drop) => {
        this.onChangeAnswers();
      })
    );

    this.subs.add(
      this.dragulaService.dropModel('words').subscribe((drop) => {
        // TODO: we should be avoiding setTimeouts where possible, although
        // it looks like this was chosen due to ng2-dragula limitations
        setTimeout(() => {
          let count = 1;

          while (this.answers.filter((answer) => answer.length > 1).length && count <= 100) {
            this.answers.forEach((answer, index) => {
              if (answer.length < 2) {
                return;
              }

              if (index === this.answers.length - 1) {
                this.available_words.push(answer.pop());

                return;
              }

              this.answers[index + 1].unshift(answer.pop());

              count++;
            });
          }

          if (count === 100) {
            throw Error('que-sort drop handler probably entered an infinite loop');
          }
        }, 100);
      })
    );

    this.setup();
  }

  setup() {
    this.correct = 'unset';

    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    this.answers = [[], [], [], []];
    this.available_words = [];
    this.question.words.forEach((element) => {
      this.available_words.push(element);
    });

    this.available_words = shuffleArray(this.available_words);
  }

  onChangeAnswers() {
    this.answerEvent.emit(this.answers);
  }

  exitGame() {
    if (this.state.user.first) {
      this.authStateService.logout();
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }

  exitImpersonatedParent() {
    if (this.state.user.first) {
      this.authStateService.signOutImpersonate(this.state);
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }
}
