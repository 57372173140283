import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthStateService } from '../auth/services/auth.service';
import { PaymentsService } from '../payments/services/payments.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSubscribedGuard implements CanActivate {
  private route = 'billing';

  constructor(private auth: AuthStateService, private payments: PaymentsService, private router: Router) {
    this.auth.state.subscribe((state) => {
      if (!state?.user?.type) {
        return;
      }

      if (environment.appId === '2') {
        this.route = 'billing';
        return;
      }

      switch (state.user.type) {
        case 'teacher':
          this.route = 'teacher/upgrade';
          break;
        case 'child':
          this.route = 'child/paywall';
          break;
        default:
          this.route = 'billing';
      }

      this.route = state?.user?.type === 'child' ? 'child/paywall' : 'billing';
    });
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const subscription = await this.payments.update();

    if (!subscription?.subscribed) {
      this.router.navigate([this.route]);

      console.log('User is not subscribed. Redirecting to paywall');

      return false;
    }

    return true;
  }
}
