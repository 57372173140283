<div class="bottom-buttons" [class.isVisible]="!isVisible">
  <button class="btn-ctr" color="accent" (click)="clickNext()" mat-flat-button *ngIf="correct === null">{{(type === 'arrows') ? 'Shoot the arrow' : 'Next Question'}}</button>
  <button class="btn-ctr" color="success" mat-flat-button *ngIf="correct === 1">
    <img src="/assets/misc-icons/tick.svg" alt="">
  </button>
  <button class="btn-ctr" color="warn" mat-flat-button *ngIf="correct === 0">
    <div class="align-btn">
      <img src="/assets/misc-icons/incorrect_x.svg" alt="">
      <div *ngIf="type !== 'drag' && type !== 'connection' && type !== 'sort'" class="center-all">
        Correct answer is <b>{{correctWord}}</b>
      </div>
      <div *ngIf="type === 'drag' || type === 'sort'" class="center-all">
        See correct words above
      </div>
      <div *ngIf="type === 'connection'" class="center-all">
        See correct pairs above
      </div>
      <div></div>
    </div>
  </button>
</div>

<button (click)="exit()" color="transparent" class="exit">
  <ion-icon src="/assets/misc-icons/exit-light.svg"></ion-icon>
</button>

