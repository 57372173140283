import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QuestionsService } from '../../services/questions.service';
import { Words } from '../../interfaces/questions.types';
import { OrientationLockService } from 'src/app/modules/common/services/orientation-lock/orientation-lock.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { State } from 'src/app/modules/auth/models/auth.types';
import { OnChanges } from '@angular/core';

const transformPosition = 'translate(-50%, 0)';
const minAngle = -75;
const maxAngle = 75;

@Component({
  selector: 'app-que-arrows',
  templateUrl: './que-arrows.component.html',
  styleUrls: ['./que-arrows.component.scss'],
  animations: [arrowPageAnimations()]
})
export class QueArrowsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('arrow') arrowRef: ElementRef<HTMLDivElement>;

  @Input() question: any = null;
  @Input() selectedTarget: number;
  @Output() arrowEvent = new EventEmitter();

  state: State;
  finishedLoading: boolean;
  // question: Question | undefined;
  targets: Words[];

  focusWord: Words;
  correctAntonym: Words;

  // selectedTarget: number;
  isAiming: boolean;
  points: number;

  QuestionAnsweredStatus = QuestionAnsweredStatus;
  questionAnsweredStatus: QuestionAnsweredStatus;

  diff: number;
  selectedTargetWord: number;

  private startPoint: number;
  private lastDiff: number;

  constructor(
    private readonly questionsService: QuestionsService,
    private readonly modalCtrl: ModalController,
    private readonly router: Router,
    private readonly orientationLockService: OrientationLockService,
    private readonly authStateService: AuthStateService,
    private audio: AudioService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTargetWord = -1;
    this.selectedTarget = -1;
    this.isAiming = false;
  }

  ngOnInit() {
    this.finishedLoading = false;
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  onTouchStart = (event: TouchEvent | MouseEvent) => {
    if (this.questionAnsweredStatus === QuestionAnsweredStatus.PENDING) {
      event.preventDefault();

      this.isAiming = true;
      this.startPoint = this.getControlPosition(event);
    }
  };

  onTouchEnd = (event: TouchEvent | MouseEvent) => {
    this.isAiming = false;

    if (this.questionAnsweredStatus === QuestionAnsweredStatus.ANSWERED) {
      return;
    }

    if (this.questionAnsweredStatus === QuestionAnsweredStatus.PENDING) {
      event.preventDefault();
    }
  };

  onTouchMove = (event: TouchEvent | MouseEvent) => {
    if (!this.isAiming) {
      return;
    }

    if (this.questionAnsweredStatus === QuestionAnsweredStatus.PENDING) {
      event.preventDefault();

      const endPoint = this.getControlPosition(event);
      const diff = this.getDiff(this.startPoint, endPoint, minAngle, maxAngle);

      this.arrowRef.nativeElement.style.transform = `${transformPosition} rotate(${diff}deg)`;
      this.selectedTargetWord = this.selectTarget(diff);
      this.arrowEvent.emit(this.selectedTargetWord);
      this.lastDiff = diff;
    }
  };

  async nextQuestion() {
    const response = await this.questionsService.answerQuestion(this.points);
    setTimeout(() => {
      // if (response.next === 'arrows') {
      //   this.setup();
      // } else {
      this.audio.play('click');
      this.router.navigate(['/child/question/' + response.next]);
      //}
    }, 500);
  }

  exitGame() {
    this.router.navigate(['child/dashboard']);
  }

  exitImpersonatedParent() {
    this.router.navigate(['child/dashboard']);
  }

  private setup = () => {
    // this.question = this.questionsService.getQuestion();
    this.correctAntonym = this.question.words.find((word) => word.points === this.question.pass_mark);
    this.focusWord = this.question.words[0];
    this.targets = this.question.words
      .filter((word, index) => index % 2 !== 0)
      .sort((a, b) => a.selection_number - b.selection_number);
    this.finishedLoading = true;
    this.startPoint = 0;
    this.points = 0;
    this.questionAnsweredStatus = QuestionAnsweredStatus.PENDING;
    this.selectedTargetWord = -1;
  };

  private answerPoints = () => {
    this.isAiming = false;
  };

  private getControlPosition = (event: TouchEvent | MouseEvent) => {
    if (window.TouchEvent && event instanceof window.TouchEvent && event.touches.length > 0) {
      return event.touches[0].pageX;
    } else if (event instanceof MouseEvent) {
      return event.clientX;
    }

    return 0;
  };

  private getDiff = (startPoint: number, endPoint: number, min: number, max: number) => {
    const diff = endPoint - startPoint;

    if (diff < min) {
      return min;
    }

    if (diff > max) {
      return max;
    }

    return diff;
  };

  private selectTarget = (diff: number) => {
    this.diff = diff;

    if (diff < -45) {
      return 0;
    }

    if (diff >= -45 && diff < -10) {
      return 1;
    }

    if (diff > 10 && diff <= 45) {
      return 2;
    }

    if (diff > 45) {
      return 3;
    }

    return -1;
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function arrowPageAnimations() {
  return trigger('fireArrowAnimation', [
    transition(':enter', [style({ height: 300 }), animate('0.3s', style({ height: 90 }))])
  ]);
}

export enum QuestionAnsweredStatus {
  PENDING,
  CORRECT,
  INCORRECT,
  ANSWERED
}
