import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStateService } from '../auth/services/auth.service';

@Injectable({ providedIn: 'root' })
export class UserUnauthenticatedGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly authStateService: AuthStateService) {}

  canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    this.authStateService.state.pipe(
      map((authState) => (environment.settings.disableLoginChecks === true ? null : authState)),
      map((authState) => (authState === null ? null : authState)),
      map((authState) => (authState ? true : this.router.createUrlTree(['landing'])))
    );
}
