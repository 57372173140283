import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State } from '../auth/models/auth.types';
import { AuthStateService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticatedGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly authStateService: AuthStateService) {}

  canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    this.authStateService.state.pipe(
      map((authState) => (authState === null ? null : authState)),
      map((authState) => (authState?.user ? this.router.createUrlTree([this.GetDashboardUrl(authState)]) : true))
    );

  private GetDashboardUrl(authState: State) {
    if (environment.appId === '1') {
      return authState.user.type === 'tutor' ? 'teacher/dashboard' : authState.user.type + '/dashboard';
    } else {
      return 'child/dashboard';
    }
  }
}
