import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  RegisterLearnerRequest,
  RegisterLearnerResponse,
  RegisterParentRequest,
  RegisterParentResponse,
  RegisterPlusRequest,
  RegisterPlusResponse,
  RegisterTeacherRequest,
  RegisterTeacherResponse,
  RegisterTutorRequest,
  RegisterTutorResponse
} from '../registration/models/registration.models';
import { ApiResponse } from './models/api.models';
import { HttpClient } from '@angular/common/http';
import {
  FindSchoolRequest,
  FindSchoolResponse,
  RecoverPasswordRequest,
  RecoverPasswordResponse,
  ResetPasswordRequest,
  SignInRequest,
  StateResponse,
  User
} from '../auth/models/auth.types';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { Features } from '../common/models/features.models';

@Injectable({ providedIn: 'root' })
export class PublicApiService {
  constructor(private readonly http: HttpClient, private readonly environmentService: EnvironmentService) {}

  registerLearner(registerFormData: RegisterLearnerRequest): Observable<ApiResponse<RegisterLearnerResponse>> {
    return this.http.post<ApiResponse<RegisterLearnerResponse>>(
      environment.baseUrl + 'register/learner',
      this.parseFormData(registerFormData)
    );
  }

  registerParent(registerFormData: RegisterParentRequest): Observable<ApiResponse<RegisterParentResponse>> {
    return this.http.post<ApiResponse<RegisterParentResponse>>(
      environment.baseUrl + 'register/parent',
      this.parseFormData(registerFormData)
    );
  }

  registerTutor(registerFormData: RegisterTutorRequest): Observable<ApiResponse<RegisterTutorResponse>> {
    return this.http.post<ApiResponse<RegisterTutorResponse>>(
      environment.baseUrl + 'register/tutor',
      this.parseFormData(registerFormData)
    );
  }

  registerTeacher(registerFormData: RegisterTeacherRequest): Observable<ApiResponse<RegisterTeacherResponse>> {
    return this.http.post<ApiResponse<RegisterTeacherResponse>>(
      environment.baseUrl + 'register/teacher',
      this.parseFormData(registerFormData)
    );
  }

  registerPlus(registerFormData: RegisterPlusRequest): Observable<ApiResponse<RegisterPlusResponse>> {
    return this.http.post<ApiResponse<RegisterPlusResponse>>(
      environment.baseUrl + 'register/plus',
      this.parseFormData(registerFormData)
    );
  }

  signIn(signInFormData: SignInRequest): Observable<ApiResponse<StateResponse>> {
    const url = this.environmentService.getBaseUrl({
      email: signInFormData.email
    } as User);

    return this.http.post<ApiResponse<StateResponse>>(url + 'sign-in', this.parseFormData(signInFormData));
  }

  findSchool(findSchoolFormData: FindSchoolRequest): Observable<ApiResponse<FindSchoolResponse>> {
    return this.http.post<ApiResponse<FindSchoolResponse>>(
      environment.baseUrl + 'find-schools',
      this.parseFormData(findSchoolFormData)
    );
  }

  recoverPassword(recoverPasswordFormData: RecoverPasswordRequest): Observable<ApiResponse<RecoverPasswordResponse>> {
    return this.http.post<ApiResponse<RecoverPasswordResponse>>(
      environment.baseUrl + 'recover-password',
      this.parseFormData(recoverPasswordFormData)
    );
  }

  resetPassword(resetPasswordFormData: ResetPasswordRequest): Observable<ApiResponse<StateResponse>> {
    return this.http.post<ApiResponse<StateResponse>>(
      environment.baseUrl + 'reset-password',
      this.parseFormData(resetPasswordFormData)
    );
  }

  getFeatures(): Observable<ApiResponse<Features>> {
    return this.http.get<ApiResponse<Features>>(environment.baseUrl + 'features');
  }

  getWondeRedirectUrl(): Observable<any> {
    return this.http.get(environment.baseUrl + 'wonde/redirect');
  }

  signInWithWonde(code: string): Observable<any> {
    return this.http.get<any>(environment.baseUrl + 'wonde/callback?code=' + code);
  }

  private parseFormData(form: any): FormData {
    const formData = new FormData();

    Object.keys(form).forEach((key) => formData.set(key, form[key]));

    return formData;
  }
}
