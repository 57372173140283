import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'app-game-buttons',
  templateUrl: './game-buttons.component.html',
  styleUrls: ['./game-buttons.component.scss']
})
export class GameButtonsComponent implements OnDestroy {
  @Output() nextBtnClick = new EventEmitter();
  @Output() exitBtnClick = new EventEmitter();
  @Input() correct: number = null;
  @Input() type = '';
  @Input() correctWord = '';
  @Input() isVisible = true;

  isAiming: boolean;
  question: any = null;
  answers: any = [[], [], []];
  correctAnswerID: string[];
  state: State;

  private numberOfAnswers = 3;

  constructor(
    private readonly questions: QuestionsService,
    private readonly audio: AudioService,
    private readonly router: Router,
    private readonly modalCtrl: ModalController,
    private readonly navController: NavController,
    private readonly authStateService: AuthStateService
  ) {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  ngOnDestroy() {}

  clickNext() {
    // Ensure lines are not shown
    this.nextBtnClick.emit('clicked');
  }

  exit() {
    this.exitBtnClick.emit('clicked');
  }
}
