import { Injectable } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class OrientationLockService {
  constructor(private readonly screenOrientation: ScreenOrientation, private readonly platform: Platform) {}

  setPortrait() {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
    });
  }

  setLandscape() {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      }
    });
  }

  unlock() {
    this.platform.ready().then(() => {
      if (this.platform.is('capacitor')) {
        this.screenOrientation.unlock();
      }
    });
  }
}
