<ion-content scrollY="false">
  <div class="alert-wrapper">
    <div class="alert-container">

      <img src="/assets/icon/info.svg">

      <h1>Are you sure you want to exit?</h1>

      <div class="buttons">
        <button class="mat-flat-button mat-secondary" mat-flat-button (click)="close(0)">Stay</button>
        <button class="mat-flat-button mat-primary" mat-flat-button color="primary" (click)="close(1)">Sign Out</button>
      </div>

    </div>
  </div>
</ion-content>
