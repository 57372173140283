export class Constants {
  static get sliderImages() {
    return [
      {
        id: 1,
        src: '/assets/characters/alien.svg'
      },

      {
        id: 2,
        src: '/assets/characters/ghost.svg'
      },

      {
        id: 3,
        src: '/assets/characters/octopus.svg'
      },

      {
        id: 4,
        src: '/assets/characters/robot.svg'
      },
      {
        id: 5,
        src: '/assets/characters/scarecrow.svg'
      }
    ];
  }
  static get delayTime() {
    return {
      correctAnswerDelay: 500,
      inCorrectAnswerDelay: 5000
    };
  }
}
