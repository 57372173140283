import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brick',
  templateUrl: './brick.component.html',
  styleUrls: ['./brick.component.scss']
})
export class BrickComponent implements OnInit {
  @Input() index: number;
  appId: string;

  constructor() {}

  ngOnInit() {
    this.appId = environment.appId;
  }
}
