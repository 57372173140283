import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { noop } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Constants } from 'src/app/util/constants';
import { QuestionAnsweredStatus } from '../que-arrows/que-arrows.component';
import { Brick } from '../../interfaces/brick-wall.page.types';
import { Question, Words } from '../../interfaces/questions.types';
import { QuestionsService } from '../../services/questions.service';
import { HttpWrapperService } from '../../services/http-wrapper.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { PopUpPage } from 'src/app/modules/common/modals/pop-up/pop-up.page';
import { State } from 'src/app/modules/auth/models/auth.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-que-brick-wall',
  templateUrl: './que-brick-wall.component.html',
  styleUrls: ['./que-brick-wall.component.scss']
})
export class QueBrickWallComponent implements OnInit, OnDestroy {
  @Input() question: any = null;
  @Output() selectedEvent = new EventEmitter();
  @Output() pointsEvent = new EventEmitter();

  state: State;
  QuestionAnsweredStatus = QuestionAnsweredStatus;
  questionAnsweredStatus: QuestionAnsweredStatus;

  newWall: Brick[][];

  correctCompoundWord: Words;
  correctCompoundWords: Words[];
  correctRowIndex: number;
  newWallWords: Words[] = [];
  selectAnswerRowIndex: any;
  selectedRowIndex: number;
  answerRowIndex: number;

  brickPlaced = false;
  appId: string;

  points: number;

  constructor(
    private readonly authStateService: AuthStateService,
    private readonly questionsService: QuestionsService,
    private readonly http: HttpWrapperService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService
  ) {}

  ngOnInit() {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
    this.points = 0;
    this.appId = environment.appId;
  }

  ngOnDestroy() {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.puzzleSetup().then();
    }, 10);
  }

  ionViewWillEnter() {
    this.puzzleSetup().then();
  }

  placeBrick(rowIndex: number) {
    if (!this.brickPlaced) {
      this.newWall = [
        [{ type: 2 }, { type: 1 }, { type: 0 }, { type: 0 }],
        [{ type: 0 }, { type: 0 }, { type: 2 }, { type: 1 }],
        [{ type: 0 }, { type: 2 }, { type: 1 }, { type: 0 }],
        [{ type: 2 }, { type: 1 }, { type: 0 }, { type: 0 }]
      ];
      this.generateWall();
      this.selectedRowIndex = this.newWall[rowIndex].findIndex((el) => el.type === 2);
      this.selectAnswerRowIndex = rowIndex;
      this.answerRowIndex = rowIndex;
      this.answerRowIndex = rowIndex;
      this.newWall[rowIndex][this.selectedRowIndex] = {
        type: 2,
        word: this.question.words[0].word,
        selectionNumber: this.question.words[0].selection_number,
        points: this.question.words[0].points
      };
      this.questionAnsweredStatus = QuestionAnsweredStatus.ANSWERED;
      const answers = this.newWall[this.selectAnswerRowIndex].filter((brick) => brick.type === 1);
      this.points = 0;
      if (this.correctRowIndex === this.answerRowIndex) {
        this.points += answers[0].points;
      }
      this.pointsEvent.emit(this.points);

      this.selectedEvent.emit(
        this.correctRowIndex === this.answerRowIndex
          ? ''
          : this.question.words[0].word + this.correctCompoundWords[1].word
      );
    }
  }

  async nextQuestion() {
    // const response = await this.questionsService.answerQuestion(this.points);
    // if (response) {
    //   setTimeout(() => {
    //     if (response.next === 'brick-wall') {
    //       this.puzzleSetup();
    //     } else {
    //       this.audio.play('click');
    //       this.router.navigate(['/child/question/' + response.next]);
    //     }
    //   }, 2000);
    // } else {
    //   await this.handleError();
    // }
  }

  async checkAnswer() {
    const answers = this.newWall[this.selectAnswerRowIndex].filter((brick) => brick.type === 1);
    let answerRevealTime = Constants.delayTime.correctAnswerDelay;
    this.brickPlaced = true;

    if (this.correctRowIndex === this.answerRowIndex) {
      this.points += answers[0].points;

      this.audio.play('correct');

      this.questionAnsweredStatus = QuestionAnsweredStatus.CORRECT;
    } else {
      this.audio.play('incorrect');
      answerRevealTime = Constants.delayTime.inCorrectAnswerDelay;
      this.questionAnsweredStatus = QuestionAnsweredStatus.INCORRECT;
    }

    setTimeout(() => {
      this.nextQuestion().then();
    }, answerRevealTime);
  }

  exitGame() {
    this.router.navigate(['child/dashboard']);
  }

  exitImpersonatedParent() {
    this.router.navigate(['child/dashboard']);
  }

  private async puzzleSetup() {
    this.questionAnsweredStatus = QuestionAnsweredStatus.PENDING;
    // this.question = Object.assign({}, this.questionsService.getQuestion());

    this.correctCompoundWord = this.question.words.find((word) => word.points > 0);
    this.correctCompoundWords = this.question.words.filter(
      (word) => word.selection_number === this.correctCompoundWord.selection_number
    );
    await this.question.words.forEach((word) => {
      if (!this.wordIsNumber(word.word)) {
        this.newWallWords.push(word);
      }
    });
    await this.newWallWords.shift();
    await this.generateWall();
    this.correctRowIndex = this.newWallWords.findIndex((word) => word.points > 0);
  }

  private wordIsNumber(word: string) {
    return word === '1' || word === '2' || word === '3';
  }

  private generateWall() {
    this.resetWall();
    this.newWallWords.forEach((word, index) => {
      const wordBricksIndex = this.newWall[index].findIndex((brick) => brick.type === 1);

      this.newWall[index].splice(wordBricksIndex, 1, {
        type: 1,
        word: this.newWallWords[index].word,
        selectionNumber: this.newWallWords[index].selection_number,
        points: this.newWallWords[index].points
      });
    });
  }

  private async handleError() {
    const err = await this.modalCtrl.create({
      component: PopUpPage,
      showBackdrop: false,
      componentProps: {
        title: 'Notice',
        text: 'Please select an answer'
      }
    });
    await err.present();
  }

  private resetWall = () =>
    (this.newWall = [
      [{ type: 2 }, { type: 1 }, { type: 0 }, { type: 0 }],
      [{ type: 0 }, { type: 0 }, { type: 2 }, { type: 1 }],
      [{ type: 0 }, { type: 2 }, { type: 1 }, { type: 0 }],
      [{ type: 2 }, { type: 1 }, { type: 0 }, { type: 0 }]
    ]);
}
