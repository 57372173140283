<ion-content class="content-bg-color">
  <div class="center-all column roll">

    <ion-grid>

      <ion-row>

        <ion-col size="4">

          <p class="description-title" [innerHtml]="question.question"></p>

          <div class="question-image">
            <img [src]="(question.image_url !== null) ? question.image_url : '/assets/account-icons/tree.svg'">
          </div>

          <p class="word focus-word">"{{ question.focus }}"</p>

        </ion-col>
        <ion-col size="8" #container>
          <input type="hidden" name="word-chosen">

          <h1 class="sentence">
            {{ question.construct[0] }}
            <span class="word-scroller-space" [class.no-border]="selected !== null" [style.width.px]="scroller_dimensions.width + 20">
              <div class="word-scroller" #scroller [style.height.px]="scroller_dimensions.height"
                   [style.marginTop.px]="scroller_dimensions.margin_top">

                <div class="item" *ngFor="let word of question.words; index as i"
                     [style.width.px]="scroller_dimensions.width > 0 ? scroller_dimensions.width+10 : 'auto'"
                     [ngClass]="getPositionOnScroller(i + 1)" (click)="selected = i + 1">{{word.word}}</div>

              </div>
            </span> {{ question.construct[1] }}
          </h1>

          <div class="roll" data-id="b" (click)="selectScrollerItem(0)"></div>
          <div class="roll" data-id="f" (click)="selectScrollerItem(1)"></div>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>
