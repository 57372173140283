import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import * as LeaderLine from 'leader-line-new';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-que-drag',
  templateUrl: './que-drag.component.html',
  styleUrls: ['./que-drag.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QueDragComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() question: any = null;
  @Output() answersDragEvent = new EventEmitter();
  @Input() correctAnswerID: any = null;
  @Input() correct: any = 'unset';
  @Input() hasCompleted = false;

  state: State;
  subs = new Subscription();

  answers: any = [[], [], []];
  original_selection: any = [];
  selections: any = [];
  copy_item: any = { id: Number, word: String, points: Number, selection_number: Number };
  last_piece_is_selection = false;
  lines = [];

  private numberOfAnswers = 3;

  constructor(
    private readonly questions: QuestionsService,
    private readonly authStateService: AuthStateService,
    private readonly dragulaService: DragulaService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService
  ) {}

  ngOnInit() {
    document.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  ngOnDestroy() {
    this.removeLines();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ngOnChanges(changeSet: SimpleChanges) {
    if (changeSet.hasOwnProperty('hasCompleted') === false) {
      return;
    }

    if (changeSet.hasCompleted.currentValue === true) {
      this.removeLines();
      this.hasCompleted = false;
    }
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.answers = [[], [], []];
    this.correct = 'unset';
    this.question.sentence_pieces = this.question.construct.split('<BREAK>');

    const last_in_array = this.question.sentence_pieces[this.question.sentence_pieces.length - 1];

    if (!last_in_array || !last_in_array.trim() || last_in_array === '') {
      this.last_piece_is_selection = true;
      this.question.sentence_pieces.pop();
    }

    this.question.selection = {};

    for (const w of this.question.words) {
      if (typeof this.question.selection[w.selection_number] === 'undefined') {
        this.question.selection[w.selection_number] = [];
      }

      this.question.selection[w.selection_number].push(w);
    }

    this.selections = [];

    // eslint-disable-next-line guard-for-in
    for (const s in this.question.selection) {
      this.selections.push(this.question.selection[s]);

      if (!this.dragulaService.find('l' + s)) {
        this.dragulaService.createGroup('l' + s, {
          copy: true,
          copyItem: (data) => {
            this.answers[parseInt(data.selection_number, 10) - 1].unshift(data);
            this.answersDragEvent.emit(this.answers);
          }
        });
      }
    }

    this.original_selection = this.question.selection;

    setTimeout(() => {
      this.drawLines();
    }, 30);
  }

  removeLines() {
    for (let index = 1; index < 4; index++) {
      if (this.lines.length === 3 && this.lines[index - 1] !== null) {
        try {
          this.lines[index - 1].remove();
        } catch (e) {}
      }
    }
  }

  drawLines() {
    for (let index = 1; index < 4; index++) {
      if (this.lines.length === 3 && this.lines[index - 1] !== null) {
        this.lines[index - 1].remove();
      }

      const word = document.querySelector('#word-space-' + index);
      const col = document.querySelector('#word-col-' + index);
      if (col !== null && word !== null) {
        this.lines[index - 1] = new LeaderLine(col, word, {
          size: 2,
          path: 'straight',
          startSocket: 'top',
          endSocket: 'bottom',
          endSocketGravity: 'auto',
          endPlug: 'disc',
          color: environment.appId === '1' ? '#009DFF' : '#8662F7'
        });
      }
    }
  }

  setWord(i, word) {
    this.answers[i][0] = word;
    this.answersDragEvent.emit(this.answers);

    this.drawLines();
  }
}
