export default {
  apiKey: 'AIzaSyACdhy9bxTpf9UPRW7yBWBRtsBhBPORizo',
  authDomain: 'chatterstars-52a34.firebaseapp.com',
  databaseURL: 'https://chatterstars-52a34.firebaseio.com',
  projectId: 'chatterstars-52a34',
  storageBucket: 'chatterstars-52a34.appspot.com',
  messagingSenderId: '295177943566',
  appId: '1:295177943566:web:a1d4357f9c72ad1ca7da5f',
  measurementId: 'G-PBL6084FH5'
};
