import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { merge, Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { HttpWrapperService } from '../../services/http-wrapper.service';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { mapTo, startWith } from 'rxjs/operators';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';

@Component({
  selector: 'app-que-cloze',
  templateUrl: './que-cloze.component.html',
  styleUrls: ['./que-cloze.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QueClozeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() question: any = null;
  @Output() cloePuzzleEvent = new EventEmitter();
  @Input() correctAnswerID: any = null;
  @Input() correct: any = 'unset';

  state: State;
  subs = new Subscription();
  selected: any = null;
  words = [];

  // question: any = null;
  answers: any = [[], [], []];
  // correct: any = 'unset';
  original_selection: any = [];
  selections: any = [];
  copy_item: any = { id: Number, word: String, points: Number, selection_number: Number };
  last_piece_is_selection = false;
  construct1: string;
  construct2: string;

  dragStart$ = this.dragulaService.drag('words').pipe(mapTo(true));
  dragEnd$ = this.dragulaService.dragend('words').pipe(mapTo(false));
  isDragging$ = merge(this.dragStart$, this.dragEnd$).pipe(startWith(false));

  private numberOfAnswers = 3;

  constructor(
    private readonly questions: QuestionsService,
    private readonly authStateService: AuthStateService,
    private readonly dragulaService: DragulaService,
    private readonly http: HttpWrapperService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService
  ) {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  ngOnInit() {
    document.addEventListener('touchmove', (e) => e.preventDefault(), { passive: false });
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.correct = 'unset';
    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    // Split the construct into two parts
    this.construct1 = this.question.construct.split('[selection]')[0];
    this.construct2 = this.question.construct.split('[selection]')[1];

    this.words = this.question.words;

    if (!this.dragulaService.find('words')) {
      this.dragulaService.createGroup('words', {
        copy: true,
        removeOnSpill: false,
        copyItem: (data) => {
          this.selected = data;
          this.cloePuzzleEvent.emit(this.selected);
        }
      });
    }

    this.correct = 'unset';

    this.selected = null;

    // this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    if (typeof this.question.construct !== 'object') {
      this.question.construct = this.question.construct.split('[selection]');
    }
  }

  onDragStart(event, data) {
    console.log('log start');

    event.dataTransfer.setData('data', data);
  }
  onDrop(event, data) {
    console.log('log drop');

    event.preventDefault();
  }
  allowDrop(event) {
    console.log('log allow');

    event.preventDefault();
  }

  setWord(word) {
    this.selected = word;
    if (this.question.words.find((e) => e.word === this.selected).points === 10) {
      return this.cloePuzzleEvent.emit('10');
    }

    this.cloePuzzleEvent.emit('0');
  }
}
