import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { Gesture, GestureController, LoadingController, ModalController } from '@ionic/angular';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'app-que-spin',
  templateUrl: './que-spin.component.html',
  styleUrls: ['./que-spin.component.scss']
})
export class QueSpinComponent implements AfterViewInit, OnDestroy {
  @ViewChild('main', { static: true, read: ElementRef }) main: ElementRef;

  @Input() question: any = null;
  @Output() selectedEvent = new EventEmitter();
  @Output() firstSpin = new EventEmitter();

  spinner_dimension: any = 0;
  spinner_offset: any = {
    top: 0,
    right: 0
  };

  rotate: any = 0;
  spin: any = 0;
  first_spin: any = 0;
  selected: any = 0;
  correctWord = '';

  correct: any = 'unset';

  swipeYCoords: number;

  constructor(
    private readonly questions: QuestionsService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService,
    private gestureCtrl: GestureController
  ) {}

  ngAfterViewInit() {
    const element = this.main.nativeElement;
    const interval = setInterval(() => {
      if (element.clientWidth && element.clientHeight) {
        this.ionViewDidEnter();
        clearInterval(interval);
      }
    }, 10);
  }

  ngOnDestroy() {}

  ionViewDidEnter() {
    this.setup();

    const gesture: Gesture = this.gestureCtrl.create(
      {
        el: this.main.nativeElement,
        threshold: 15,
        gestureName: 'verticalSwipe',
        direction: 'y',
        onMove: (ev) => this.swipe(ev, 'onMove'),
        onEnd: (ev) => this.swipe(ev, 'onEnd')
      },
      true
    );

    gesture.enable();

    const el = this.main.nativeElement;

    this.spinner_dimension = el.clientWidth;

    this.spinner_offset.top = (this.spinner_dimension - el.clientHeight) / 2;
    this.spinner_offset.right = this.spinner_dimension * 0.55;
  }

  setup() {
    this.correct = 'unset';

    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    this.question.words_left = this.question.words.slice(0, 3);
    this.question.words_right = this.question.words.slice(3);

    this.rotate = 0;
    this.spin = 0;
    this.first_spin = 0;
    this.selected = 0;
  }

  selectItem(selected) {
    if (this.selected !== selected) {
      let dir = selected > this.selected ? 1 : 0;
      if (this.selected === 5 && selected === 0) {
        dir = 1;
      }
      if (this.selected === 0 && selected === 5) {
        dir = 0;
      }
      this.selected = selected;
      this.rotateNow(dir, 0);
    }
    this.selectedEvent.emit(this.selected.toString());
  }

  swipe(event, type) {
    if (type === 'onMove') {
      this.swipeYCoords = event.deltaY;
    }
    if (type === 'onEnd') {
      if (event.deltaY < -150) {
        this.rotateNow(0, 1);
      }
      if (event.deltaY > 150) {
        this.rotateNow(1, 1);
      }
    }
  }

  rotateNow(dir, manual) {
    if (!this.first_spin) {
      this.first_spin++;
    }

    let active = this.selected;

    if (dir) {
      if (manual) {
        active = active < 5 ? active + 1 : 0;
      }
      this.spin--;
    } else {
      if (manual) {
        active = active > 0 ? active - 1 : 5;
      }
      this.spin++;
    }

    this.selected = active;

    this.rotate = 60 * this.spin;
    this.selectedEvent.emit(this.selected.toString());
    this.firstSpin.emit(this.first_spin);
  }

  exitGame() {
    this.router.navigate(['child/dashboard']);
  }

  exitImpersonatedParent() {
    this.router.navigate(['child/dashboard']);
  }
}
