import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { DragulaModule } from 'ng2-dragula';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppHttpInterceptor } from './common/http-interceptor/app-interceptor';
import { environment } from '../environments/environment';
import { ModalsModule } from './modules/common/modals/modals.module';
import { NgxStripeModule, StripeService } from 'ngx-stripe';
import { MatButtonModule } from '@angular/material/button';
import * as Hammer from 'hammerjs';
import { NgxLeaderLineModule } from 'ngx-leader-line';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ExitQuestionsPageModule } from './modules/common/modals/exit-questions/exit-questions.module';
import { PopUpPageModule } from './modules/common/modals/pop-up/pop-up.module';
import { WindowService } from './modules/common/services/window/window.service';
import version from 'src/environments/version';

if (environment.bugsnag) {
  Bugsnag.start({
    apiKey: environment.bugsnag.apiKey,
    appType: environment.appId === '1' ? 'primary' : 'plus',
    appVersion: version,
    collectUserIp: true,
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: environment.name
  });
}

@Injectable({ providedIn: 'root' })
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {
      direction: Hammer.DIRECTION_ALL
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollPadding: false,
      scrollAssist: true
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    DragulaModule.forRoot(),
    HttpClientModule,
    ModalsModule,
    MatButtonModule,
    NgxStripeModule.forRoot(environment.stripe.publishableKey),
    HammerModule,
    NgxLeaderLineModule,
    ExitQuestionsPageModule,
    PopUpPageModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenOrientation,
    StripeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useFactory: () => (environment.bugsnag ? new BugsnagErrorHandler() : new ErrorHandler())
    },
    WindowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
