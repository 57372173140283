import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { noop, Subscription } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { Constants } from 'src/app/util/constants';
import { State } from '../../../auth/models/auth.types';
import { Words } from '../../interfaces/questions.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { PopUpPage } from 'src/app/modules/common/modals/pop-up/pop-up.page';

@Component({
  selector: 'app-que-oddoneout',
  templateUrl: './que-oddoneout.component.html',
  styleUrls: ['./que-oddoneout.component.scss']
})
export class QueOddOneOutComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() question: any = null;
  @Output() answerEvent = new EventEmitter();

  subs = new Subscription();

  state: State;
  answers: Words[] = [];
  correct: any = 'unset';
  correctAnswerCount = 0;
  correctAnswerID: string[];
  words_row1: Words[];
  words_row2: Words[];

  constructor(
    private readonly questions: QuestionsService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly authStateService: AuthStateService,
    private readonly audio: AudioService
  ) {}

  ngOnInit() {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
    this.setup();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.correct = 'unset';
    // eslint-disable-next-line id-blacklist
    this.correctAnswerCount = this.question.words.filter((number) => number.points > 0).length;
    this.answers = [];
    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    this.words_row1 = this.question.words.slice(0, 3);
    this.words_row2 = this.question.words.slice(3);
  }

  toggleWord(word) {
    if (!this.isSelected(word)) {
      this.answers.push(word);
      console.log('Emit word:' + JSON.stringify(word));
    } else {
      const answers = this.answers.filter((w) => w.id !== word.id);
      this.answers = answers;
    }
    this.answerEvent.emit(this.answers);
  }

  isSelected(word: Words) {
    const existingWord = this.answers.find((w) => w.id === word.id);
    if (existingWord != null) {
      return true;
    }
    return false;
  }

  calculatePoints(): number {
    const totalPoints: number = this.answers.map((a) => a.points).reduce((a, b) => a + b);
    return totalPoints;
  }

  exitGame() {
    if (this.state.user.first) {
      this.authStateService.logout();
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }

  exitImpersonatedParent() {
    if (this.state.user.first) {
      this.authStateService.signOutImpersonate(this.state);
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }
}
