import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DemoService } from '../../services/demo.service';

@Component({
  selector: 'app-demo-banner',
  templateUrl: './demo-banner.component.html',
  styleUrls: ['./demo-banner.component.scss']
})
export class DemoBannerComponent {
  public active = false;

  constructor(private readonly demo: DemoService, private readonly router: Router) {
    this.demo.active$.subscribe((active) => (this.active = active));
  }

  disable(): void {
    this.demo.deactivate();
    this.router.navigate(['/teacher/dashboard']);
  }
}
