import { Injectable } from '@angular/core';
import { environment as baseEnvironment } from 'src/environments/environment';
import { environment as stagingEnvironment } from 'src/environments/environment.staging';
import { environment as stagingPlusEnvironment } from 'src/environments/environment.eleven-plus.staging';
import { users } from 'src/environments/users';
import { User } from 'src/app/modules/auth/models/auth.types';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  getPaymentsConfiguration = (user: User): RevenueCatConfig => {
    if (!this.isReviewing(user)) {
      console.log('EnvironmentService: returning default payments configuration');
      return baseEnvironment.revenuecat;
    }

    console.log('EnvironmentService: returning review payments configuration');

    return baseEnvironment.appId === '2' ? stagingPlusEnvironment.revenuecat : stagingEnvironment.revenuecat;
  };

  getBaseUrl = (user: User = null): string => {
    if (!user || !this.isReviewing(user)) {
      console.log('EnvironmentService: returning default API configuration');
      return baseEnvironment.baseUrl;
    }

    console.log('EnvironmentService: returning review API configuration');

    return baseEnvironment.appId === '2' ? stagingPlusEnvironment.baseUrl : stagingEnvironment.baseUrl;
  };

  private isReviewing = (user: User): boolean => {
    const identifier = user?.username ?? user?.email;
    return identifier && users.review.includes(identifier);
  };
}

export interface RevenueCatConfig {
  keys: {
    android: string;
    ios: string;
    stripe: string;
  };
}
