<ion-header class="ion-hide-lg-up">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button menu="teacher">
                <img src="/assets/misc-icons/menu.svg">
            </ion-menu-button>
        </ion-buttons>

        <ion-buttons slot="end">
            <ion-button>
                <img src="/assets/branding/star.svg">
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-split-pane contentId="teacher">
        <ion-menu menuId="teacher" contentId="teacher" type="overlay">
            <ion-header class="ion-hide-lg-down">
                <ion-toolbar>
                    <ion-title>ChatterStars</ion-title>
                    <ion-button slot="end" fill="clear" (click)="navigate('/teacher/dashboard')">
                        <img src="/assets/branding/star.svg" />
                    </ion-button>
                </ion-toolbar>
            </ion-header>

            <ion-content class="menu">
                <ion-item button (click)="navigate('/teacher/dashboard')">
                    <ion-label>Dashboard</ion-label>
                </ion-item>

                <ion-item button (click)="toggle(sections.learners);" *ngIf="!wondePending">
                    <ion-label>Pupils</ion-label>
                    <ion-icon name="chevron-down-outline" slot="end" [class.open]="expanded === sections.learners">
                    </ion-icon>
                </ion-item>

                <ion-item-group *ngIf="expanded === sections.learners">
                    <ion-item button (click)="navigate('/teacher/learners')">
                        <ion-label>Manage pupils</ion-label>
                    </ion-item>

                    <ion-item button (click)="exportLogins()" [disabled]="demoActive">
                        <ion-label>Download logins</ion-label>
                        <img src="/assets/icon/formats/xlsx.svg" class="file-format-icon" slot="end">
                    </ion-item>

                    <ion-item button (click)="navigate('/teacher/learners/create')" *ngIf="!wondeEnabled"
                        [disabled]="demoActive">
                        <ion-label>Add individual pupil</ion-label>
                    </ion-item>

                    <ion-item button (click)="navigate('/teacher/learners/import')" *ngIf="!wondeEnabled"
                        [disabled]="demoActive">
                        <ion-label>Add multiple pupils</ion-label>
                        <img src="/assets/icon/formats/xlsx.svg" class="file-format-icon" slot="end">
                    </ion-item>

                    <ion-item button (click)="navigate('/teacher/groups')">
                        <ion-label>Manage groups</ion-label>
                    </ion-item>
                </ion-item-group>

                <ion-item button (click)="toggle(sections.reports)" *ngIf="!wondePending">
                    <ion-label>Reports</ion-label>
                    <ion-icon name="chevron-down-outline" slot="end" [class.open]="expanded === sections.reports">
                    </ion-icon>
                </ion-item>

                <ion-item-group *ngIf="expanded === sections.reports">
                    <ion-item button (click)="exportLeaderboard()" [disabled]="demoActive">
                        <ion-label>Star leaderboard</ion-label>
                        <img src="/assets/icon/formats/pdf.svg" class="file-format-icon" slot="end">
                    </ion-item>

                    <ion-item button (click)="exportStarOfWeek()" [disabled]="demoActive">
                        <ion-label>Star of the week</ion-label>
                        <img src="/assets/icon/formats/pdf.svg" class="file-format-icon" slot="end">
                    </ion-item>

                    <ion-item button (click)="exportProgress()" [disabled]="demoActive">
                        <ion-label>Full progress report</ion-label>
                        <img src="/assets/icon/formats/xlsx.svg" class="file-format-icon" slot="end">
                    </ion-item>
                </ion-item-group>

                <ion-item button (click)="toggle(sections.support)">
                    <ion-label>Support</ion-label>
                    <ion-icon name="chevron-down-outline" slot="end" [class.open]="expanded === sections.support">
                    </ion-icon>
                </ion-item>

                <ion-item-group *ngIf="expanded === sections.support">
                    <ion-item button id="videos-trigger">
                        <ion-label>Tutorial videos</ion-label>
                    </ion-item>

                    <ion-popover trigger="videos-trigger" triggerAction="click" side="right" [arrow]="true">
                        <ng-template>
                            <ion-content class="ion-padding">
                                <p>
                                    We will shortly be updating this section to include a range of useful video
                                    resources
                                    for teachers.
                                </p>

                                <p>
                                    In the meantime, please visit our
                                    <a href="https://www.youtube.com/channel/UCbNjgCtD3VjF4Bc39Sf6o-A" target="_blank">
                                        YouTube channel
                                    </a>
                                    for more information.
                                </p>
                            </ion-content>
                        </ng-template>
                    </ion-popover>

                    <ion-item button (click)="navigate('/support/0')">
                        <ion-label>Email support</ion-label>
                    </ion-item>

                    <ion-item href="https://chatterstars.co.uk/book-meeting/">
                        <ion-label>Book a free onboarding session</ion-label>
                    </ion-item>

                    <ion-item href="tel:+442080668350">
                        <ion-label>Call 0208 066 8350</ion-label>
                    </ion-item>

                    <ion-item button (click)="navigate('/support/0')">
                        <ion-label>Feedback</ion-label>
                    </ion-item>

                    <ion-item button (click)="openLiveChat()">
                        <ion-label>Live Chat</ion-label>
                    </ion-item>
                </ion-item-group>

                <ion-item button (click)="toggle(sections.integrations)" *ngIf="!wondePending">
                    <ion-label>Integrations</ion-label>
                    <ion-icon name="chevron-down-outline" slot="end" [class.open]="expanded === sections.integrations">
                    </ion-icon>
                </ion-item>

                <ion-item-group *ngIf="expanded === sections.integrations">
                    <ion-item button (click)="navigate('/teacher/integrations/wonde')" [disabled]="demoActive">
                        <ion-label>Wonde</ion-label>
                        <img src="/assets/branding/wonde.png" height="20px" slot="end">
                    </ion-item>
                </ion-item-group>

                <ion-item button (click)="navigate('/teacher/profile')">
                    <ion-label>Profile</ion-label>
                </ion-item>

                <ion-item button (click)="logout()">
                    <ion-label>Log out</ion-label>
                </ion-item>
            </ion-content>

            <ion-item *ngIf="!wondePending">
                <ion-label>Filter by group</ion-label>
            </ion-item>

            <ion-toolbar *ngIf="!wondePending">
                <ion-select [multiple]="true" (ionChange)="onFilter($event)" [selectedText]="selectedText"
                    [(ngModel)]="selected" interface="popover">
                    <ion-select-option *ngIf="groups.length === 0" disabled>
                        No groups available
                    </ion-select-option>
                    <ion-select-option *ngFor="let group of groups" [value]="group.id">
                        {{ group.name }}
                    </ion-select-option>
                </ion-select>

                <ion-buttons slot="end">
                    <ion-button (click)="clearFilters()">
                        <ion-icon icon="trash-outline"></ion-icon>
                    </ion-button>
                    <ion-button (click)="navigate('/teacher/groups')">
                        <ion-icon icon="cog-outline"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>

            <ion-footer button (click)="navigate('/teacher/integrations/wonde')">
                <app-wonde-widget></app-wonde-widget>
            </ion-footer>
        </ion-menu>

        <ion-router-outlet id="teacher" [animated]="false"></ion-router-outlet>
    </ion-split-pane>

    <app-demo-banner></app-demo-banner>
</ion-content>