<ion-content class="content-bg-color">
    <div class="center-all column puzzle-container" *ngIf="finishedLoading; else stillLoading">

        <p class="instruction" [innerHTML]="question.question"></p>

        <div class="target-container">
            <div class="target" *ngFor="let target of targets; index as idx">
                <div class="target-icon">
                    <div *ngIf="(target.selection_number === selectedTarget) && !isAiming" [@fireArrowAnimation] class="fired-arrow"></div>
                </div>
                <div class="word" [ngClass]="{ selected: target.selection_number === selectedTargetWord }">{{ target.word }}</div>
            </div>
        </div>

        <div class="aim-container" (touchstart)="onTouchStart($event)" (mousedown)="onTouchStart($event)" (touchend)="onTouchEnd($event)" (mouseup)="onTouchEnd($event)" (touchmove)="onTouchMove($event)" (mousemove)="onTouchMove($event)">
            <div class="direction-marker"></div>
            <div class="focus-word" *ngIf="focusWord">{{ focusWord.word }}</div>
            <div class="arrow" #arrow></div>
        </div>
    </div>
</ion-content>

<ng-template #stillLoading>
    <div class="center-all column selectt"></div>
</ng-template>
