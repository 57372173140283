
<ion-content *ngIf="question" class="content-bg-color">
    <div class="center-all column sortt">
        <p class="instruction" [innerHTML]="question.question"></p>

        <ion-grid>
            <ion-row>
                <ion-col *ngIf="newWall">
                    <div class="words">
                        <div>
                            <p *ngIf="correctCompoundWords" class="focus-word"> {{ question.words[0].word }} </p>
                        </div>
                        <img [src]="'assets/misc-icons/obstacle.svg'">
                    </div>

                    <div class="wall">
                        <!-- <div class="mortar" *ngIf="appId === '2'"></div> -->
                        <div class="brick-wall">
                            <div class="row" *ngFor="let row of newWall; index as i">
                                <div class="drag-bricks" *ngFor="let brick of newWall[i]; index as bi" [ngSwitch]="brick.type">
                                    <app-brick *ngSwitchCase="0" [index]="bi"></app-brick>
                                    <app-word-brick *ngSwitchCase="1" [word]="brick.word"></app-word-brick>
                                    <app-target-brick *ngSwitchCase="2" (click)="placeBrick(i)" [index]="bi" [word]="brick.word"></app-target-brick>
                                </div>
                            </div>
                        </div>
                    </div>

                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ion-content>
