import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController, SelectCustomEvent } from '@ionic/angular';
import { Group, LearnersService } from '../services/learners.service';
import { I18nPluralPipe } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { State, User } from 'src/app/modules/auth/models/auth.types';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { WindowService } from 'src/app/modules/common/services/window/window.service';
import { WondeService } from '../services/wonde.service';
import { DemoService } from '../services/demo.service';

enum ESection {
  learners,
  reports,
  support,
  account,
  integrations
}

@Component({
  selector: 'app-teacher-layout',
  templateUrl: './teacher.layout.html',
  styleUrls: ['./teacher.layout.scss'],
  providers: [I18nPluralPipe]
})
export class TeacherLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  demoActive = false;
  expanded: ESection | null;
  selected: number[] = [];
  groups: Group[];
  sections = ESection;
  selectedText = 'All groups';
  user: User;
  wondeEnabled = false;
  wondePending = false;

  constructor(
    private readonly auth: AuthStateService,
    private readonly demo: DemoService,
    private readonly elementRef: ElementRef,
    readonly learners: LearnersService,
    private readonly menu: MenuController,
    private readonly puralPipe: I18nPluralPipe,
    private readonly router: Router,
    private readonly window: WindowService,
    private readonly wonde: WondeService
  ) {}

  ngOnInit(): void {
    this.auth.state
      .pipe(
        untilDestroyed(this),
        filter((state) => state !== null),
        map((state) => state)
      )
      .subscribe((state: State) => {
        this.user = state?.user ?? null;
      });

    this.learners.groups$.subscribe((groups) => {
      this.groups = groups.filter((group) => !this.wondeEnabled || group.wonde_enabled);
    });

    this.learners.groupIds$.subscribe({
      next: (ids) => {
        this.selected = ids;

        this.selectedText =
          ids.length === 0
            ? 'All groups'
            : `${ids.length} ${this.puralPipe.transform(ids.length, {
                '=0': 'groups',
                '=1': 'group',
                other: 'groups'
              })}`;
      }
    });

    this.wonde.enabled.subscribe((enabled) => (this.wondeEnabled = enabled));
    this.wonde.pending.subscribe((pending) => (this.wondePending = pending));
    this.demo.active$.subscribe((active) => (this.demoActive = active));
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    /**
     * Appends hubspot live chat script to DOM to allow teachers to open a support chat when executed within a browser context
     */
    if (!Capacitor.isNativePlatform() && environment.hubspot.enabled) {
      // Script element
      const script = document.createElement('script');

      // Script attributes
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.type = 'text/javascript';
      script.src = '//js-eu1.hs-scripts.com/25438938.js';

      this.elementRef.nativeElement.appendChild(script);
    }
  }

  clearFilters(): void {
    this.selected = [];
    this.learners.setGroups(this.selected);
  }

  dismiss(): void {
    this.menu.close('teacher');
  }

  exportLeaderboard(): void {
    const link = this.getExportUrl('auth/pdf-report');
    const name = this.user.first_name + '_report.pdf';
    FileSaver.saveAs(link, name);
  }

  exportLogins(): void {
    const link = this.getExportUrl('auth/download-users-csv');
    const name = this.user.first_name + '_report.csv';
    FileSaver.saveAs(link, name);
  }

  exportProgress(): void {
    const link = this.getExportUrl('auth/csv-report');
    const name = this.user.first_name + '_report.csv';
    FileSaver.saveAs(link, name);
  }

  exportStarOfWeek(): void {
    const link = this.getExportUrl('auth/best-performer-certificate');
    const name = this.user.first_name + '_report.pdf';
    FileSaver.saveAs(link, name);
  }

  logout(): void {
    this.auth.logout();
  }

  navigate(route: string): void {
    this.router.navigate([route]);
    this.dismiss();
  }

  onFilter(event: Event): void {
    this.selected = (event as SelectCustomEvent).detail.value;
    this.learners.setGroups(this.selected);
  }

  openLiveChat(): void {
    if (!this.window.native().HubSpotConversations?.widget) {
      return;
    }

    this.window.native().HubSpotConversations.widget.open();
  }

  toggle(key: ESection): void {
    if (this.expanded === key) {
      this.expanded = null;

      return;
    }

    this.expanded = key;
  }

  private getExportUrl(route: string): string {
    const base = environment.baseUrl;
    const groups = this.selected.map((id) => `groups[]=${id}&`).join('');
    const token = this.user.api_token;

    return `${base}${route}?api_token=${token}&${groups}`;
  }
}
