import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MatButtonModule } from '@angular/material/button';

import { ExitQuestionsPage } from './exit-questions.page';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, MatButtonModule],
  declarations: [ExitQuestionsPage]
})
export class ExitQuestionsPageModule {}
