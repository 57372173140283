<div class="center-all column dragg" *ngIf="question">

        <p class="instruction">{{question.question}}</p>

        <div class="fillable">
                    <h2 class="sentence">
                        {{ question.construct[0] }}
                        <div class="word-scroller-space" [class.no-border]="selected !== null"  >
                            {{selected}}                          
                        </div> 
                        {{ question.construct[1] }}
                    </h2>       
        </div>
        <div class="words-container">
            <div (click)="setWord(word.word)" *ngFor="let word of words; index as i" class="words" >
                <div > {{word.word}} </div>
            </div>
        </div>

</div>

