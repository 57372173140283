<ion-content scrollX="false" scrollY="false" class="content-bg-color">

    <div class="center-all column slidee">

        <p class="instruction" [innerHTML]="question.question"></p>

        <div class="focus">
            <img [src]="(question.image_url) ? question.image_url : '/assets/account-icons/tree.svg'">
            <p class="focus-word">"{{ question.focus }}"</p>
        </div>

        <div id="new-carousel">
            <ul #list [style.marginLeft.px]="slider.margin_left">
                <li class="slide" *ngFor="let word of words; index as i" (click)="selectNumber(i)" [class.selected]="selected === i" [class.first]="i === 0">{{ word.word }}</li>
            </ul>
            <div class="roll" data-id="b" (click)="select(0)"></div>
            <div class="roll" data-id="f" (click)="select(1)"></div>
        </div>

    </div>

</ion-content>