import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ModalOptions } from '@ionic/angular';

import { Answer, Question, QuestionState } from '../interfaces/questions.types';
import { HttpClient } from '@angular/common/http';
import { PrivateApiService } from '../../api/private-api.service';
import { PopUpPage } from '../../common/modals/pop-up/pop-up.page';
import { AuthStateService } from '../../auth/services/auth.service';
import { LastResultState, State } from '../../auth/models/auth.types';

@Injectable({ providedIn: 'root' })
export class QuestionsService {
  public first_set: any = false;
  public set: any = false;
  public answers: any = [];
  public wait: any = false;
  public has_asked = false;
  public state;

  public type = 0;

  StorageKey = 'DataState';
  StorageKey2 = 'DataStore';

  constructor(
    private readonly privateApiService: PrivateApiService,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly https: HttpClient,
    private readonly authStateService: AuthStateService
  ) {
    this.authStateService.state.subscribe((state) => (this.state = state));
  }

  async getSet(first) {
    const loading = await this.loading.create({
      message: 'Please wait...'
    });
    await loading.present();
    const questionSet = this.privateApiService.getQuestionSet();
    this.answers = [];

    loading.dismiss();

    return questionSet;
  }

  setQuestions(questions: Question[]) {
    this.set = questions;
    this.createState(questions);
  }

  getQuestion(type) {
    return this.set[this.type];
  }

  getNextQuestionType(type) {
    this.type = type;

    if (this.set.length === this.type) {
      return 'complete';
    }

    return this.getQuestionType(this.set[this.type].question_type);
  }

  getQuestionType(type) {
    switch (type) {
      case 1:
        return 'roll';
      case 2:
        return 'slide';
      case 3:
        return 'spin';
      case 4:
        return 'sort';
      case 5:
        return 'select';
      case 6:
        return 'drag';
      case 7:
        return 'brick-wall';
      case 8:
        return 'arrows';
      case 9:
        return 'anagram';
      case 10:
        return 'cloze';
      case 11:
        return 'oddoneout';
      case 12:
        return 'connection';
    }
  }

  answerQuestion(points) {
    this.answers.push(points);

    const key = this.answers.length - 1;

    // Find the correct word(s)
    const correctAnswer: string[] = new Array(8);
    const correctAnswerID: string[] = new Array(8);
    let i = 0;
    for (const answer of this.set[key].words) {
      if (answer.points > 0) {
        correctAnswer[i] = answer.word;
        correctAnswerID[i] = answer.id;
        i++;
      }
    }

    this.updateAnswerState(this.answers);

    return {
      correct: this.answers[key] >= this.set[key].pass_mark ? 1 : 0,
      correctWord: correctAnswer,
      correctWordID: correctAnswerID,
      // next: this.getNextQuestionType()
      next: 2
    };
  }

  getCorrectAnswers() {
    const answers = [];
    // eslint-disable-next-line guard-for-in
    for (const key in this.answers) {
      answers.push(this.answers[key] >= this.set[key].pass_mark ? 1 : 0);
    }

    return answers;
  }

  resetAnswers() {
    this.answers = [];
  }

  async submitAnswers() {
    const postData = { answers: [] };
    const correct = this.getCorrectAnswers();

    // eslint-disable-next-line guard-for-in
    if (this.answers.length > 0) {
      for (const i in this.answers) {
        if (this.answers.hasOwnProperty(i)) {
          const answer = {
            question_id: this.set[i].id,
            correct: correct[i],
            points: this.answers[i],
            level: this.set[i].level
          };
          postData.answers[i] = answer;
        }
      }
    }

    const state: State = this.state;
    const oldLevel = state.user.level;

    return new Promise((resolve, reject) => {
      this.privateApiService.submitAnswers(postData).subscribe(
        (result) => {
          if (result.success) {
            if (state.students) {
              const currentStudentIndex = state.students.findIndex((student) => student.id === state.user.id);
              state.students[currentStudentIndex] = result.payload.user;
            }

            const lastResultSet: LastResultState = {
              gems: result.payload.gems_earned,
              total_questions: result.payload.total_questions,
              correct_answers: result.payload.correct_answers,
              stars: result.payload.stars,
              levelUp: state.user.level > oldLevel ? true : false,
              message: result.payload.message
            };

            this.authStateService.update({
              ...state,
              user: result.payload.user,
              lastResult: lastResultSet
            });
          }

          resolve(true);
        },
        (err) => {
          this.handleError(err);
          reject(err);
        }
      );
    });
  }

  async setDummyData() {
    return new Promise((resolve, reject) => {
      this.https.get(`http://localhost:8080/mocks`).subscribe((response: any) => {
        console.log('response', response);
        this.set = response.questions;
        resolve(1);
        // this.getQuestion();
      });
    });
  }

  createState(questions: Question[], isFirst: boolean = false) {
    const newState: QuestionState = {
      set: questions,
      isFirst,
      answers: []
    };

    this.updateState(newState);
  }

  createOriginalState(questions: Question[], isFirst: boolean = false) {
    const newState: QuestionState = {
      set: questions,
      isFirst,
      answers: []
    };

    this.storeOriginalQuestionSet(newState);
  }

  updateState = (state: QuestionState) => {
    this.removeState();
    localStorage.setItem(`${this.StorageKey}`, JSON.stringify(state));
  };

  updateAnswerState = (state: Answer[]) => {
    const currentState: QuestionState = this.getState();
    this.removeState();

    currentState.answers = state;
    localStorage.setItem(`${this.StorageKey}`, JSON.stringify(state));
  };

  getState = () => JSON.parse(localStorage.getItem(this.StorageKey));

  removeState = () => {
    localStorage.removeItem(this.StorageKey);
  };

  storeOriginalQuestionSet = (state) => {
    localStorage.removeItem(this.StorageKey2);
    localStorage.setItem(`${this.StorageKey2}`, JSON.stringify(state));
  };

  getOriginalState = () => JSON.parse(localStorage.getItem(this.StorageKey2));

  updateDnDQuestions(data: Question[]) {
    if (data !== null) {
      data.forEach((question) => {
        if (question.type === 'Drag the words') {
          question.construct = this.addFullStop(question.construct);
        }
      });
    }

    return data;
  }

  private handleError = (err) => {
    const modalConfig: ModalOptions = {
      component: PopUpPage,
      showBackdrop: false,
      componentProps: {
        title: 'Failed to retrieve questions',
        text: err.error.error.errorMessage
      },
      cssClass: 'pop-up'
    };

    this.modalCtrl.create(modalConfig).then((modal) => modal.present());
  };

  private clearQuestions = () => {
    const state = this.getState();
    state.set = null;
    state.isFirst = false;
    this.updateState(state);
  };

  private addFullStop(selection: string): string {
    const regExp = /[.!?\\-]/;

    return regExp.test(selection.charAt(selection.length - 1)) ? selection : selection + '.';
  }
}
