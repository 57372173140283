<ion-content class="content-bg-color">
  <div class="container">
    <div>
      <p class="instruction">{{question.question}}</p>
    </div>
    <div class="words-container">
      <div>
        <ul class="one-section">
          <li id="mapping-{{item.selection_number}}"
              class="list-item"
              *ngFor="let item of mapping "
              [class.mapped-and-selected]="(el === selection)"
              (click)="connect($event.currentTarget)">
            <span [ngStyle]="{'background-color': isWrongAnswer ? item.bgColor : ''}" [class.select]="el === ('mapping-'+item.selection_number) && !isWrongAnswer">{{item.word}}</span>
          </li>
        </ul>
      </div>
      <div>
        <ul class="two-section">
          <li id="mapping2-{{item.selection_number}}"
              class="list-item"
              *ngFor="let item of mapping2 "
              (click)="connect2($event.currentTarget)"
              [class.mapped-and-selected]="(el2 === selection2)">
            <span [ngStyle]="{'background-color': isWrongAnswer ? item.bgColor : ''}" [class.select]="el2 === ('mapping2-'+item.selection_number) && !isWrongAnswer">{{item.word}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ion-content>
