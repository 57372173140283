import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, StateResponse, User } from '../models/auth.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
const StorageKey = 'AuthState';

@Injectable({ providedIn: 'root' })
export class AuthStateService {
  private readonly stateTrigger$ = new BehaviorSubject<StateResponse | State | null>(null);
  private readonly state$: Observable<State | null> = this.stateTrigger$;

  constructor(private readonly router: Router) {
    this.load();
  }

  get state(): Observable<State> {
    return this.state$;
  }

  get apiToken(): Observable<string> {
    return this.state$.pipe(map((state) => state?.apiToken));
  }

  update = (state: StateResponse) => {
    localStorage.setItem(StorageKey, JSON.stringify(state));
    this.stateTrigger$.next({
      ...state,
      apiToken: state.user?.api_token ?? '',
      environment: environment.name
    });
  };

  setImpersonate = (state: State, student: User) => {
    const impersonatedState = {
      ...state,
      impersonatedParent: state.user,
      user: student,
      apiToken: student.api_token
    };
    localStorage.setItem(StorageKey, JSON.stringify(impersonatedState));
    this.stateTrigger$.next(impersonatedState);
  };

  logout = () => {
    localStorage.clear();
    this.stateTrigger$.next(null);
    this.router.navigate(['landing']).then();
  };

  clearLocalStorage = () => {
    localStorage.clear();
    this.stateTrigger$.next(null);
  };

  signOutImpersonate(state: State) {
    const newState = {
      ...state,
      user: state.impersonatedParent,
      apiToken: state.impersonatedParent?.api_token ?? '',
      impersonatedParent: null
    };
    localStorage.setItem(StorageKey, JSON.stringify(newState));
    this.stateTrigger$.next(newState);
    this.router.navigate(['landing']).then();
  }

  private updateLocalStorage = (state) => {
    localStorage.setItem(StorageKey, JSON.stringify(state));
  };

  private load = () => {
    const payload = localStorage.getItem(StorageKey);
    if (payload !== null) {
      try {
        const state = JSON.parse(payload);
        this.update(state);
        if (state instanceof Object && state.hasOwnProperty('user') && state.hasOwnProperty('students')) {
          // if (
          //   state.hasOwnProperty('impersonatedParent') &&
          //   !state.hasOwnProperty('adminImpersonate') &&
          //   state.impersonatedParent
          // ) {
          //   this.router.navigate(['/child/dashboard']).then();
          // }
        }
      } finally {
      }
    }
  };
}
