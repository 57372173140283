import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { PopUpPage } from './pop-up.page';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, IonicModule, MatButtonModule],
  declarations: [PopUpPage],
  entryComponents: [PopUpPage]
})
export class PopUpPageModule {}
