import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as LeaderLine from 'leader-line-new';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-que-connections',
  templateUrl: './que-connection.component.html',
  styleUrls: ['./que-connection.component.scss']
})
export class QueConnectionComponent implements OnDestroy, OnChanges {
  @Input() question: any = null;
  @Input() isWrongAnswer: any = null;
  @Input() hasCompleted = false;
  @Output() connectionEvent = new EventEmitter();
  words = [];

  mapping = [];
  mapping2 = [];
  mappedObj = {};
  mappedObj2 = {};
  selection;
  selection2;
  mapped = false;

  savedMappings = [];
  mappedIndex: number;
  el: string;
  el2: string;

  state: State;

  constructor(private readonly authStateService: AuthStateService) {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.puzzleSetup().then();
    }, 10);
  }

  ngOnDestroy() {}

  ngOnChanges(changeSet: SimpleChanges) {
    if (changeSet.hasCompleted.currentValue === true) {
      this.clearLinks();
      this.hasCompleted = false;
    }
  }

  ionViewWillEnter() {
    this.puzzleSetup().then();
  }

  clearLinks() {
    this.savedMappings.forEach((e) => e.line.remove());
  }

  selectionWasMapped(id) {
    for (let i = 0; i < this.savedMappings.length; i++) {
      if (this.savedMappings[i].mapping === id) {
        this.mappedIndex = i;
        return true;
      }
    }
  }

  selectionWasMapped2(id) {
    for (let i = 0; i < this.savedMappings.length; i++) {
      if (this.savedMappings[i].mapping2 === id) {
        this.mappedIndex = i;
        return true;
      }
    }
  }

  leader(id, id2) {
    const startEl = document.getElementById(id);
    const endEl = document.getElementById(id2);

    return new LeaderLine(startEl, endEl, {
      endPlugOutline: false,
      color: this.setColor(id)
    });
  }

  connect(el: EventTarget) {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    this.el = el.id;
    this.el2 = undefined;

    if (this.selectionWasMapped(el.id)) {
      // Already mapped so remove current mapping
      this.unMap(el);
    }

    if (this.mapped) {
      this.selection = undefined;
      this.selection2 = undefined;
      this.mapped = false;
    }

    if (this.selection === el.id) {
      this.el = '';
      this.selection = '';
    } else {
      this.selection = el.id;
    }

    if (this.isMapped()) {
      //save
      //mark mapped
      const l = this.leader(this.selection, this.selection2);
      this.save(l);
    }

    this.emitAnswer();
  }

  emitAnswer() {
    if (this.savedMappings.length === this.mapping.length) {
      this.submitted();
    }
  }

  save(l) {
    this.savedMappings.push({
      mapping: this.selection,
      mapping2: this.selection2,
      line: l
    });
  }

  isMapped() {
    this.mapped = this.selection && this.selection2;
    return this.mapped;
  }

  unMap(el: HTMLElement) {
    const currentMap = this.savedMappings.filter((m) => m.mapping === el.id);
    if (currentMap[0] !== null) {
      currentMap[0].line.remove();
      if (this.mappedObj !== null) {
        this.mappedObj[currentMap[0].mapping] = null;
      }
      if (this.mappedObj2 !== null) {
        this.mappedObj2[currentMap[0].mapping2] = null;
      }
      this.savedMappings = this.savedMappings.filter((m) => m.mapping !== el.id);
    }
  }

  unMap2(el2: HTMLElement) {
    const currentMap = this.savedMappings.filter((m) => m.mapping2 === el2.id);
    if (currentMap[0] !== null) {
      currentMap[0].line.remove();
      if (this.mappedObj !== null) {
        this.mappedObj[currentMap[0].mapping] = null;
      }
      if (this.mappedObj2 !== null) {
        this.mappedObj2[currentMap[0].mapping2] = null;
      }
      this.savedMappings = this.savedMappings.filter((m) => m.mapping2 !== el2.id);
    }
  }

  connect2(el2: EventTarget) {
    if (!(el2 instanceof HTMLElement)) {
      return;
    }

    this.el2 = el2.id;
    this.el = undefined;

    if (this.selectionWasMapped2(el2.id)) {
      // Already mapped so remove current mapping
      this.unMap2(el2);
    }

    if (this.mapped) {
      this.selection = undefined;
      this.selection2 = undefined;
      this.mapped = false;
    }

    if (this.selection2 === el2.id) {
      this.el2 = '';
      this.selection2 = '';
    } else {
      this.selection2 = el2.id;
    }

    if (this.isMapped()) {
      //save
      //mark mapped
      const l = this.leader(this.selection, this.selection2);
      this.save(l);
    }
    this.emitAnswer();
  }

  setColor(id) {
    switch (id) {
      case 'mapping-' + 1:
        return '#FFB64C';
      case 'mapping-' + 2:
        return '#FF6C6C';
      case 'mapping-' + 3:
        return '#6AA9FF';
      case 'mapping-' + 4:
        return '#5BC980';
      default:
        return '#FFB64C';
    }
  }

  submitted() {
    let valid = true;
    this.savedMappings.forEach((e) => {
      if (valid && e.mapping.split('-')[1] !== e.mapping2.split('-')[1]) {
        valid = false;
        return false;
      }
    });
    this.connectionEvent.emit(valid ? 'correct' : 'wrong');
  }

  private async puzzleSetup() {
    this.words = this.question.words;
    const length = this.words.length / 2;
    for (let i = 1; i <= length; i++) {
      const pairs = this.words.filter((e) => e.selection_number === i);
      this.mapping.push(pairs[0]);
      this.mapping2.push(pairs[1]);
    }
    for (let j = 1; j <= length; j++) {
      this.mapping[j - 1].bgColor = this.setColor('mapping-' + j);
      this.mapping2[j - 1].bgColor = this.setColor('mapping-' + j);
    }
    const temp = this.shuffle(this.mapping);
    const temp2 = this.shuffle(this.mapping2);

    this.mapping = temp;
    this.mapping2 = temp2;
  }

  private shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
}
