import { Component, OnInit } from '@angular/core';
import { WondeService, WondeStatus } from '../../services/wonde.service';

@Component({
  selector: 'app-wonde-widget',
  templateUrl: './wonde-widget.component.html',
  styleUrls: ['./wonde-widget.component.scss']
})
export class WondeWidgetComponent implements OnInit {
  enabled = false;
  icon: string;
  loading = false;
  message = 'Fetching Wonde status';
  status: WondeStatus;

  constructor(private readonly wonde: WondeService) {}

  ngOnInit(): void {
    this.wonde.status.subscribe((status) => {
      if (!status.status || status.status === 'disabled') {
        return;
      }

      this.status = status;

      this.message = '';
      this.loading = false;
      this.icon = null;
      this.enabled = status.status !== 'disabled';

      if (this.status.status === 'pending') {
        this.message = 'Pending approval';
        this.loading = true;
        return;
      }

      switch (this.status.sync_status) {
        case 'queued':
          this.message = 'Wonde sync queued';
          this.loading = true;
          break;

        case 'in_progress':
          this.message = 'Wonde sync in progress';
          this.loading = true;
          break;

        case 'completed':
          this.message = 'Wonde up to date';
          this.icon = 'checkmark-circle-outline';
          break;

        case 'enabled':
          this.message = 'Wonde enabled';
          this.icon = 'checkmark-circle-outline';
          break;

        case 'unavailable':
        default:
          this.message = 'Wonde status unknown';
          this.icon = 'help-circle-outline';
      }
    });
  }
}
