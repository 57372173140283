import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private readonly storage: Storage) {
    this.create();
  }

  get(key: StorageKeys): Promise<string | boolean> {
    console.log(`StorageService: retrieving ${key}`);
    return this.storage.get(key);
  }

  set(key: StorageKeys, value: string | boolean) {
    console.log(`StorageService: setting ${key}`);
    return this.storage.set(key, value);
  }

  private create() {
    this.storage.create();
  }
}

export enum StorageKeys {
  demoMode = 'demoMode',
  enableSound = 'enableSound'
}

export enum EnableSoundValues {
  Y = 'Y',
  N = 'N'
}
