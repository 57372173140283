<ion-content scrollY="false" class="content-bg-color">
  <div class="main" #main>

    <div class="focus">
      <p class="instruction" [innerHTML]="question.question"></p>
      <img [src]="(question.image_url) ? question.image_url : '/assets/account-icons/tree.svg'">
      <p class="focus-word">"{{ question.focus }}"</p>
    </div>

    <div class="spinner-container" [style.height.px]="spinner_dimension" [style.width.px]="spinner_dimension" [style.top.px]="0 - spinner_offset.top" [style.right.px]="0 - spinner_offset.right">

      <img class="triangle" src="/assets/misc-icons/triangle-up.svg">

      <div class="spinner" [style.transform]="'rotate(' + rotate + 'deg)'">

        <div *ngIf="first_spin === 0" class="instruction">
          <div class="inner">
          </div>
        </div>
        <div *ngIf="first_spin === 0" class="instruction-text">Turn the wheel to select the correct synonym</div>

        <div class="item" (click)="selectItem(i)" *ngFor="let word of question.words; index as i" [class.active]="selected === i && first_spin > 0" id="{{ i }}">
          <div class="inner"></div>
        </div>
        <div class="word-item" (click)="selectItem(i)" *ngFor="let word of question.words; index as i" [class.active]="selected === i && first_spin > 0" id="{{ i }}">{{ word.word }}</div>

        <div class="circle-center"></div>

      </div>

    </div>

    <div class="roll" data-id="b" (click)="rotateNow(0, 1)"></div>
    <div class="roll" data-id="f" (click)="rotateNow(1, 1)"></div>

  </div>
</ion-content>
