import { Injectable } from '@angular/core';

export interface IWindow extends Window {
  HubSpotConversations?: {
    widget: {
      open: () => void;
      status: () => void;
    };
  };
}

@Injectable()
export class WindowService {
  public readonly native = (): IWindow => window as unknown as IWindow;
}
