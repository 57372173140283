import { Injectable } from '@angular/core';
import { PublicApiService } from 'src/app/modules/api/public-api.service';
import { Features } from '../../models/features.models';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private features: Features = {} as Features;

  constructor(private readonly api: PublicApiService) {}

  enabled(key: string): boolean {
    return this.exists(key) && this.features[key].enabled;
  }

  meta(key: string): { [key: string]: string | number | boolean } {
    if (!this.exists(key)) {
      return {};
    }

    return this.features[key].meta;
  }

  init(): void {
    console.log('FeatureService: Init');
    this.api.getFeatures().subscribe(
      (features) => (this.features = features.payload),
      () => console.log('FeatureService: Failed to retrieve features from API')
    );
  }

  private exists(key: string): boolean {
    return this.features.hasOwnProperty(key);
  }
}
