import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthStateService } from '../auth/services/auth.service';
import { filter, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from './models/api.models';
import { AnswerApiResponse, QuestionsApiResponse } from '../game/interfaces/questions.types';
import { State, StudentUpdateRequest, User } from '../auth/models/auth.types';
import { EquipItemResponse, PurchaseItemResponse, StoreItem, UnequipItem } from '../rewards/models/purchase-item';
import { CategoryItems } from '../rewards/models/store-category';
import { PurchasesOfferings } from '@awesome-cordova-plugins/purchases/ngx';
import { CheckoutResponse, PortalResponse, Subscription } from '../payments/models/payments.models';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { formatISO } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class PrivateApiService {
  private apiToken: string;
  private baseUrl: string;
  private plus: boolean;

  constructor(
    private readonly authStateService: AuthStateService,
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService
  ) {
    this.baseUrl = this.environmentService.getBaseUrl();

    this.authStateService.state
      .pipe(
        tap(console.log),
        filter((state: State) => state !== null)
      )
      .subscribe((state: State) => {
        this.apiToken = state.apiToken;
        this.plus = state.user.type === 'child';
        this.baseUrl = this.environmentService.getBaseUrl(state.user);
      });
  }

  parentAddChild(addChildForm): Observable<any> {
    return this.http.post(this.baseUrl + 'auth/add-child', this.parseFormData(addChildForm), {
      params: { api_token: this.apiToken }
    });
  }

  impersonateChild(username): Observable<any> {
    return this.http.post(this.baseUrl + `auth/impersonate-child`, this.parseFormData(username), {
      params: { api_token: this.apiToken }
    });
  }

  adminImpersonateUser(apiKey: string): Observable<any> {
    return this.http.post(this.baseUrl + `admin-user-impersonate?api_token=` + apiKey, null, {});
  }

  checkUserName(id, username): Observable<any> {
    return this.http.get(this.baseUrl + `check-username/${id}/${username}`, {
      params: { api_token: this.apiToken }
    });
  }

  checkNewUserName(username): Observable<any> {
    return this.http.get(this.baseUrl + `check-username/new/${username}`, {
      params: { api_token: this.apiToken }
    });
  }

  getQuestionSet(): Observable<ApiResponse<QuestionsApiResponse>> {
    return this.http.post<ApiResponse<QuestionsApiResponse>>(this.baseUrl + 'auth/questions/get', null, {
      params: { api_token: this.apiToken }
    });
  }

  selectCharacter(characterId: number): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(
      this.baseUrl + `auth/store-item/equip-initial-character`,
      this.parseFormData({ store_item_id: characterId }),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  submitAnswers(data): Observable<AnswerApiResponse> {
    return this.http.post<AnswerApiResponse>(this.baseUrl + 'auth/questions/answer', data, {
      params: { api_token: this.apiToken }
    });
  }

  getChallenges(): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(this.baseUrl + 'auth/challenge/all', {
      params: { api_token: this.apiToken }
    });
  }

  updateStudent(data: StudentUpdateRequest): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.baseUrl + 'auth/update-little-child', this.parseFormData(data), {
      params: { api_token: this.apiToken }
    });
  }

  sendSupportData(supportForm): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.baseUrl + 'auth/support-request', this.parseFormData(supportForm), {
      params: { api_token: this.apiToken }
    });
  }

  sendQuoteRequest(quoteRequestForm): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.baseUrl + 'auth/quote-request', this.parseFormData(quoteRequestForm), {
      params: { api_token: this.apiToken }
    });
  }

  sendSupportDataPlus(supportForm): Observable<any> {
    return this.http.post<ApiResponse<any>>(
      this.baseUrl + 'auth/support-request-plus',
      this.parseFormData(supportForm),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  sendUpgradeRequest(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.baseUrl + 'auth/request-upgrade', {
      params: { api_token: this.apiToken }
    });
  }

  getStoreCategories(type: string): Observable<ApiResponse<CategoryItems[]>> {
    return this.http.get<ApiResponse<any>>(
      this.baseUrl + 'auth/store-item/categories/' + type + '/' + environment.appId,
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  getStoreSubCategories(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.baseUrl + 'auth/store-item/sub-categories/' + environment.appId, {
      params: { api_token: this.apiToken }
    });
  }

  purchaseItem(request: StoreItem): Observable<ApiResponse<PurchaseItemResponse>> {
    return this.http.post<ApiResponse<PurchaseItemResponse>>(
      this.baseUrl + 'auth/store-item/purchase-item',
      this.parseFormData(request),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  equipItem(request: StoreItem): Observable<ApiResponse<EquipItemResponse>> {
    return this.http.post<ApiResponse<EquipItemResponse>>(
      this.baseUrl + 'auth/store-item/equip-item',
      this.parseFormData(request),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  unEquipItem(request: UnequipItem): Observable<ApiResponse<EquipItemResponse>> {
    return this.http.post<ApiResponse<EquipItemResponse>>(
      this.baseUrl + 'auth/store-item/un-equip-item',
      this.parseFormData(request),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  getPurchasedItems(category: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(
      this.baseUrl + `auth/store-item/get-purchased-items/${category.toString()}`,
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  getTeacherStatistics(): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.baseUrl + 'auth/teacher-stats', {
      params: { api_token: this.apiToken }
    });
  }

  updateTeacherSettings(user): Observable<any> {
    return this.http.post<ApiResponse<EquipItemResponse>>(
      this.baseUrl + 'auth/update-account',
      this.parseFormData(user),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  deleteTeacherAccount(id): Observable<any> {
    return this.http.post<ApiResponse<EquipItemResponse>>(
      this.baseUrl + 'auth/delete-account',
      this.parseFormData(id),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  updateChild(editChildForm): Observable<any> {
    return this.http.post(this.baseUrl + 'auth/update-child', this.parseFormData(editChildForm), {
      params: { api_token: this.apiToken }
    });
  }

  deleteChild(data): Observable<any> {
    return this.http.post(this.baseUrl + 'auth/delete-child', data, {
      params: { api_token: this.apiToken }
    });
  }

  getChildCredentials(id: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.baseUrl + `auth/get-child-credentials/${id}`, {
      params: { api_token: this.apiToken }
    });
  }

  getOfferings(): Observable<ApiResponse<PurchasesOfferings>> {
    const path = this.plus ? 'auth/offerings/plus' : 'auth/offerings';

    return this.http.get<ApiResponse<PurchasesOfferings>>(this.baseUrl + path, {
      params: { api_token: this.apiToken }
    });
  }

  getSubscription(): Observable<ApiResponse<Subscription>> {
    const path = this.plus ? 'auth/subscription/plus' : 'auth/subscription';

    return this.http.get<ApiResponse<Subscription>>(this.baseUrl + path, {
      params: { api_token: this.apiToken }
    });
  }

  checkout(price_id: string): Observable<ApiResponse<CheckoutResponse>> {
    const path = this.plus ? 'auth/checkout/plus' : 'auth/checkout';

    return this.http.post<ApiResponse<CheckoutResponse>>(
      this.baseUrl + path,
      this.parseFormData({
        cancel_url: environment.appUrl + 'billing',
        price_id,
        success_url: environment.appUrl + 'billing/success'
      }),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  portal(): Observable<ApiResponse<PortalResponse>> {
    const path = this.plus ? 'auth/portal/plus' : 'auth/portal';

    return this.http.post<ApiResponse<PortalResponse>>(
      this.baseUrl + path,
      this.parseFormData({
        return_url: environment.appUrl + 'billing'
      }),
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  getLearners(query: string = ''): Observable<any> {
    return this.http.get(this.baseUrl + `v1/manage/learners${query}`, {
      params: { api_token: this.apiToken }
    });
  }

  getLearner(id: number): Observable<any> {
    return this.http.get(this.baseUrl + `v1/manage/learners/${id}`, {
      params: { api_token: this.apiToken }
    });
  }

  storeLearner(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'v1/manage/learners', data, {
      params: { api_token: this.apiToken }
    });
  }

  updateLearner(id: number, data: any): Observable<any> {
    return this.http.put(this.baseUrl + 'v1/manage/learners/' + id, data, {
      params: { api_token: this.apiToken }
    });
  }

  getGroups(): Observable<any> {
    return this.http.get(this.baseUrl + `v1/manage/groups`, {
      params: { api_token: this.apiToken }
    });
  }

  storeGroup(data: any): Observable<any> {
    console.log(data);
    return this.http.post(this.baseUrl + 'v1/manage/groups', data, {
      params: { api_token: this.apiToken }
    });
  }

  updateGroup(id: number, data: any): Observable<any> {
    return this.http.put(this.baseUrl + 'v1/manage/groups/' + id, data, {
      params: { api_token: this.apiToken }
    });
  }

  deleteGroup(id: number): Observable<any> {
    return this.http.delete(this.baseUrl + 'v1/manage/groups/' + id, {
      params: { api_token: this.apiToken }
    });
  }

  getProfile(): Observable<any> {
    return this.http.get(this.baseUrl + 'v1/manage/account', {
      params: { api_token: this.apiToken }
    });
  }

  updateProfile(data: any): Observable<any> {
    return this.http.put(this.baseUrl + 'v1/manage/account', data, {
      params: { api_token: this.apiToken }
    });
  }

  getAnalyticsForGroups(groups: number[] = [], start: Date, end: Date): Observable<any> {
    const url =
      this.baseUrl +
      `v1/manage/analytics?filter[groups]=${groups.join(',')}` +
      `&start=${this.formatDate(start)}&end=${this.formatDate(end)}`;

    return this.http.get(url, {
      params: { api_token: this.apiToken }
    });
  }

  getAnalyticsForLearner(learnerId: string, start: Date, end: Date): Observable<any> {
    const url =
      this.baseUrl +
      `v1/manage/analytics?learner_id=${learnerId}` +
      `&start=${this.formatDate(start)}&end=${this.formatDate(end)}`;

    return this.http.get(url, {
      params: { api_token: this.apiToken }
    });
  }

  getWondeStatus(): Observable<any> {
    return this.http.get(this.baseUrl + 'v1/manage/integrations/wonde', {
      params: { api_token: this.apiToken }
    });
  }

  requestWondeSync(groups: number[] = null): Observable<any> {
    return this.http.post(
      this.baseUrl + 'v1/manage/integrations/wonde',
      { groups },
      {
        params: { api_token: this.apiToken }
      }
    );
  }

  private parseFormData(form: any): FormData {
    const formData = new FormData();
    Object.keys(form).forEach((key) => formData.set(key, form[key] ? form[key] : ''));

    return formData;
  }

  private formatDate(date: Date): string {
    return formatISO(date, {
      format: 'basic',
      representation: 'date'
    });
  }
}
