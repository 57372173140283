<ion-content [scrollY]="false" class="content-bg-color">
<div class="center-all column dragg" *ngIf="question">

    <p class="instruction" [innerHTML]="question.question">{{ question.question }}</p>

    <div class="fillable">
        <div class="one-section" *ngFor="let piece of question.sentence_pieces; index as i">
            <p class="sentence">{{ piece }}</p>
            <div id="word-space-{{(i + 1)}}" class="space" *ngIf="last_piece_is_selection || i < question.sentence_pieces.length - 1" [class.filled]="answers[i].length > 0" [dragula]="'l' + (i + 1)" [dragulaModel]="answers[i]">
                <span >{{ answers[i][0] ? answers[i][0].word : '' }}</span>
            </div>
        </div>
    </div>

    <ion-grid>
        <ion-row>
            <ion-col *ngFor="let selection of selections; index as i">                
                <div [dragula]="'l' + (i + 1)" [dragulaModel]="selections[i]" class="word-list" id="word-col-{{(i + 1)}}">
                    <div *ngFor="let word of selection" class="wl-item" [class.filled]="answers[i][0] && answers[i][0] === word">
                        <div [class.correctWord]="!correct && correctAnswerID.includes(word.id)" id="{{word.id}}" class="word" (click)="setWord(i,word)">{{ word.word }}</div>
                    </div>

                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
</ion-content>