import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { Gesture, GestureController, LoadingController, ModalController } from '@ionic/angular';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';

@Component({
  selector: 'app-que-roll',
  templateUrl: './que-roll.component.html',
  styleUrls: ['./que-roll.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QueRollComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container', { read: ElementRef }) col_container: ElementRef;
  @ViewChild('scroller') scroller: ElementRef;

  @Input() question: any = null;
  @Output() selectedEvent = new EventEmitter();
  // state: State;
  timeInterval: number;
  scroller_dimensions: any = {
    height: 0,
    width: 0,
    margin_top: 0
  };

  scroller_height: any = 0;
  scroller_margin_top: any = 0;

  selected: any = null;
  items: any = 0;

  loaded: any = 0;

  correct: any = 'unset';
  correctWord = '';

  swipeYCoords: number;

  constructor(
    private readonly questions: QuestionsService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService,
    private elem: ElementRef,
    private gestureCtrl: GestureController
  ) {}

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {}

  async ngAfterViewInit() {
    const gesture: Gesture = this.gestureCtrl.create(
      {
        el: this.scroller.nativeElement,
        threshold: 15,
        gestureName: 'verticalSwipe',
        direction: 'y',
        onMove: (ev) => this.swipe(ev, 'onMove'),
        onEnd: (ev) => this.swipe(ev, 'onEnd')
      },
      true
    );

    gesture.enable();

    setTimeout(() => {
      this.setScrollerHeight();
    }, 100);
  }

  swipe(event, type) {
    if (type === 'onMove') {
      this.swipeYCoords = event.deltaY;
    }
    if (type === 'onEnd') {
      if (event.deltaY < -100) {
        this.selectScrollerItem(1);
      }
      if (event.deltaY > 100) {
        this.selectScrollerItem(0);
      }
    }
  }

  setScrollerHeight() {
    this.scroller_dimensions.height = this.col_container.nativeElement.clientHeight;
    this.scroller_dimensions.margin_top = 0 - (this.scroller_dimensions.height - 38) / 2;

    for (const child of this.question.words) {
      const text = document.createElement('span');
      document.body.appendChild(text);
      text.style.position = 'absolute';
      text.style.whiteSpace = 'nowrap';
      text.innerHTML = child.word;
      const elWidth = text.offsetWidth + 70;
      if (elWidth > this.scroller_dimensions.width) {
        this.scroller_dimensions.width = elWidth;
      }
      document.body.removeChild(text);
    }

    this.items = this.scroller.nativeElement.children.length;
  }

  ionViewDidEnter() {
    this.scroller_dimensions.height = this.col_container.nativeElement.clientHeight;
    this.scroller_dimensions.margin_top = 0 - (this.scroller_dimensions.height - 38) / 2;

    for (const child of this.question.words) {
      const text = document.createElement('span');
      document.body.appendChild(text);
      text.style.position = 'absolute';
      text.style.whiteSpace = 'nowrap';
      text.innerHTML = child.word;
      const elWidth = text.offsetWidth + 70; // padding
      if (elWidth > this.scroller_dimensions.width) {
        this.scroller_dimensions.width = elWidth;
      }
      document.body.removeChild(text);
    }

    this.items = this.scroller.nativeElement.children.length;
  }

  ionViewWillEnter() {
    // this.setup();
  }

  setup() {
    this.correct = 'unset';

    this.selected = null;

    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');

    if (typeof this.question.construct !== 'object') {
      this.question.construct = this.question.construct.split('[selection]');
    }
  }

  selectScrollerItem(dir) {
    const s = this.selected;

    if (dir) {
      if (s == null) {
        this.selected = 4;
      } else {
        this.selected += s < this.items ? 1 : 0;
      }
    } else {
      if (s == null) {
        this.selected = 3;
      } else {
        this.selected -= s > 1 ? 1 : 0;
      }
    }
  }

  getPositionOnScroller(index) {
    const s = this.selected;
    const i = index;
    let position;
    let side;

    if (this.selected) {
      this.selectedEvent.emit(this.selected);
    }

    if (this.selected == null) {
      if (i <= 3) {
        if (i === 1) {
          return 'prev three';
        }
        if (i === 2) {
          return 'prev two';
        }
        if (i === 3) {
          return 'prev one';
        }
      } else {
        if (i === 4) {
          return 'next one';
        }
        if (i === 5) {
          return 'next two';
        }
        if (i === 6) {
          return 'next three';
        }
        if (i > 6) {
          return 'next hidden';
        }
      }
    } else {
      if (i > s) {
        position = i - s;
        side = 1;
      } else if (i < s) {
        position = s - i;
        side = 0;
      } else {
        return 'selected';
      }

      if (position === 1) {
        return (!side ? 'prev' : 'next') + ' one';
      }
      if (position === 2) {
        return (!side ? 'prev' : 'next') + ' two';
      }
      if (position === 3) {
        return (!side ? 'prev' : 'next') + ' three';
      }
      if (position > 3) {
        return (!side ? 'prev' : 'next') + ' hidden';
      }
    }
  }

  exitGame() {
    this.router.navigate(['child/dashboard']);
  }

  exitImpersonatedParent() {
    this.router.navigate(['child/dashboard']);
  }
}
