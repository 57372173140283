import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { environment } from '../../environments/environment';
// import { AuthStateService } from './auth.service';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthStateService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpWrapperService {
  data: any;
  apiServerUrl: any;
  apiToken: string = null;
  response: any;
  user: any = {};
  errors: any = null;

  constructor(public http: HttpClient, private authService: AuthStateService) {
    this.data = null;
    this.apiServerUrl = environment.baseUrl;
    this.authService.state
      .pipe(filter((state) => state !== null))
      .subscribe((state) => (this.apiToken = state.user?.api_token));
  }

  getFromServer(url): Promise<any> {
    return new Promise((resolve, reject) => {
      const options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      };

      if (this.authRoute(url)) {
        url += '?api_token=' + this.apiToken;
      }

      this.http.get(this.apiServerUrl + url, options).subscribe(
        (data) => {
          resolve(this.checkForErrorsAndReport(data) ? false : data);
        },
        (error) => {
          this.checkForErrorsAndReport(error);
          resolve(false);
        }
      );
    });
  }

  postToServer(url, data, headers): Promise<any> {
    return new Promise((resolve, reject) => {
      const options = {
        headers: new HttpHeaders(headers)
      };

      if (this.authRoute(url)) {
        url += '?api_token=' + this.apiToken;
      }

      this.http.post(this.apiServerUrl + url, this.toQueryString(data, ''), options).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (data) => resolve(this.checkForErrorsAndReport(data) ? false : data),
        (error) => {
          this.checkForErrorsAndReport(error);
          resolve(false);
        }
      );
    });
  }

  putInServer(url, data, headers): Promise<any> {
    return new Promise((resolve, reject) => {
      const options = {
        headers: new HttpHeaders(headers)
      };

      if (this.authRoute(url)) {
        url += '?api_token=' + this.apiToken;
      }

      this.http.put(this.apiServerUrl + url, this.toQueryString(data, ''), options).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (data) => {
          resolve(this.checkForErrorsAndReport(data) ? false : data);
        },
        (error) => {
          this.checkForErrorsAndReport(error);
          resolve(false);
        }
      );
    });
  }

  authRoute(url) {
    return url !== '/sign-in' && url !== '/register' && url !== '/find-school' && url !== '/recover-password';
  }

  checkForErrorsAndReport(data) {
    const err = data.error;
    if (typeof err !== 'undefined') {
      this.errors = [];
      if (typeof err === 'object') {
        // eslint-disable-next-line guard-for-in
        for (const e in err) {
          this.errors.push(err[e] instanceof Array ? err[e][0] : err[e]);
        }
      } else {
        this.errors.push(err);
      }
      return 1;
    } else {
      this.errors = null;
      return 0;
    }
  }

  toQueryString(obj, prefix) {
    const str = [];
    let k;
    let v;
    for (const p in obj) {
      if (!obj.hasOwnProperty(p)) {
        continue;
      }
      // eslint-disable-next-line no-bitwise
      if (~p.indexOf('[')) {
        k = prefix ? prefix + '[' + p.substring(0, p.indexOf('[')) + ']' + p.substring(p.indexOf('[')) : p;
      } else {
        k = prefix ? prefix + '[' + p + ']' : p;
      }
      v = obj[p];
      str.push(typeof v == 'object' ? this.toQueryString(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
    return str.join('&');
  }

  validatePostData(data, exceptions) {
    const errors = [];
    for (const d in data) {
      if (exceptions.indexOf(d) === -1) {
        if (data[d] === '' || data[d] == null || typeof data[d] === 'undefined') {
          errors.push(d);
        }
      }
    }

    let error = '';

    for (const e of errors) {
      switch (e) {
        case 'first_name':
          error += 'Please enter your first name<br>';
          break;
        case 'last_name':
          error += 'Please enter your last name<br>';
          break;
        case 'email':
          error += 'Please enter a valid email address<br>';
          break;
        case 'password':
          error += 'Please enter a valid password<br>';
          break;
        case 'school_name':
          error += 'Please enter a valid school name<br>';
          break;
        case 'school_postcode':
          error += 'Please enter a valid postcode for the school<br>';
          break;
        case 'role':
          error += 'Please enter a valid job role<br>';
          break;
        case 'issue':
          error += 'Please enter a support issue<br>';
          break;
        case 'message':
          error += 'Please enter a message for your support request.<br>';
          break;
      }
    }

    return errors.length > 0 ? error : false;
  }

  getErrorString() {
    let error = '';

    for (const i in this.errors) {
      if (typeof this.errors[i] !== 'object') {
        error += this.errors[i] + '<br>';
      } else if (typeof this.errors[i] === 'object') {
        const keys = Object.keys(this.errors[i]);

        // eslint-disable-next-line guard-for-in
        for (const key in keys) {
          const keyValue = keys[key];
          let value = '';
          const object = this.errors[i];

          if (Array.isArray(object[keyValue])) {
            // eslint-disable-next-line guard-for-in
            for (const err in object[keyValue]) {
              const errorObj = object[keyValue];
              value += errorObj[err] + ' ';
            }
          } else {
            value = object[keyValue];
          }
          error += keyValue + ' : ' + value + '<br>';
        }
      }
    }

    return this.errors.length > 0 ? error : 'Something went wrong';
  }

  fileUpload(data: FormData): Observable<any> {
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const httpOptions = {
      headers
    };

    const url = this.apiServerUrl + 'auth/import-students?api_token=' + this.apiToken;

    return this.http.post<string>(url, data, httpOptions);
  }
}
