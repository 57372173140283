import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GamePageComponent } from './modules/game/pages/game-page/game-page.component';

import { UserAuthenticatedGuard } from './modules/guards/user-authenticated.guard';
import { UserUnauthenticatedGuard } from './modules/guards/user-unauthenticated.guard';
import { ParentalGateGuard } from './modules/guards/parental-gate.guard';
import { environment } from '../environments/environment';
import { UserSubscribedGuard } from './modules/guards/user-subscribed.guard';
import { TeacherLayoutComponent } from './teacher/layout/teacher.layout';

const routes: Routes = environment.maintenance
  ? [
      {
        path: '**',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule)
      }
    ]
  : [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule)
      },
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'landing'
      },
      {
        path: 'landing',
        loadChildren: () => import('./modules/auth/pages/landing/landing.module').then((m) => m.LandingPageModule),
        canActivate: [UserAuthenticatedGuard]
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./modules/auth/pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyPageModule)
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import('./modules/auth/pages/terms-and-conditions/terms-and-conditions.module').then(
            (m) => m.TermsAndConditionsPageModule
          )
      },
      {
        path: 'user-impersonate/:apiToken',
        loadChildren: () => import('./modules/auth/pages/landing/landing.module').then((m) => m.LandingPageModule)
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./modules/auth/pages/onboarding/onboarding.module').then((m) => m.OnboardingModule)
      },
      {
        path: 'sign-in',
        loadChildren: () => import('./modules/auth/pages/sign-in/sign-in.module').then((m) => m.SignInPageModule),
        canActivate: [UserAuthenticatedGuard]
      },
      {
        path: 'recover-password',
        loadChildren: () =>
          import('./modules/auth/pages/recover-password/recover-password.module').then(
            (m) => m.RecoverPasswordPageModule
          )
      },
      {
        path: 'reset-password/:token',
        loadChildren: () =>
          import('./modules/auth/pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
      },
      {
        path: 'register/parent-or-teacher',
        loadChildren: () =>
          import('./modules/registration/pages/parent-or-teacher/parent-or-teacher.module').then(
            (m) => m.ParentOrTeacherPageModule
          )
      },
      {
        path: 'register/teacher',
        loadChildren: () =>
          import('./modules/registration/pages/teacher/teacher.module').then((m) => m.TeacherPageModule)
      },
      {
        path: 'register/parent',
        loadChildren: () => import('./modules/registration/pages/parent/parent.module').then((m) => m.ParentPageModule)
      },
      {
        path: 'register/teacher/school',
        loadChildren: () => import('./modules/registration/pages/school/school.module').then((m) => m.SchoolPageModule)
      },
      {
        path: 'register/teacher/add-school',
        loadChildren: () =>
          import('./modules/registration/pages/school/add-school/add-school.module').then((m) => m.AddSchoolPageModule)
      },
      {
        path: 'register/add/pupil',
        loadChildren: () =>
          import('./modules/registration/pages/add-child/add-child.module').then((m) => m.AddChildPageModule)
      },
      {
        path: 'parent/dashboard',
        loadChildren: () =>
          import('./modules/dashboard/pages/parent-dashboard/parent-dashboard/parent-dashboard.module').then(
            (m) => m.ParentDashboardPageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'parent/users',
        loadChildren: () =>
          import('./modules/users/pages/parent/parent-users/parent-users.module').then((m) => m.ParentUsersPageModule),
        canActivate: [UserUnauthenticatedGuard, UserSubscribedGuard]
      },
      {
        path: 'parent/users/add',
        loadChildren: () =>
          import('./modules/users/pages/parent/parent-add-user/parent-add-user.module').then(
            (m) => m.ParentAddUserPageModule
          ),
        canActivate: [UserUnauthenticatedGuard, UserSubscribedGuard]
      },
      {
        path: 'parent/users/edit',
        loadChildren: () =>
          import('./modules/users/pages/parent/parent-edit-user/parent-edit-user.module').then(
            (m) => m.ParentEditUserModule
          ),
        canActivate: [UserUnauthenticatedGuard, UserSubscribedGuard]
      },
      {
        path: 'parent/settings',
        loadChildren: () =>
          import('./modules/settings/pages/parent-settings/parent-settings.module').then(
            (m) => m.ParentSettingsPageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'child/dashboard',
        loadChildren: () =>
          import('./modules/dashboard/pages/child-dashboard/child-dashboard.module').then(
            (m) => m.ChildDashboardPageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'child/rewards',
        loadChildren: () => import('./modules/rewards/pages/rewards.module').then((m) => m.RewardsModule),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'child/game-page',
        loadChildren: () => import('./modules/game/pages/game-page/game-page.module').then((m) => m.GamePageModule),
        canActivate: [UserUnauthenticatedGuard, UserSubscribedGuard]
      },
      {
        path: 'child/game-page/:set',
        loadChildren: () => import('./modules/game/pages/game-page/game-page.module').then((m) => m.GamePageModule),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'register/add/:type',
        loadChildren: () =>
          import('./modules/registration/pages/add-child/add-child.module').then((m) => m.AddChildPageModule)
      },
      {
        path: 'register/child',
        loadChildren: () => import('./modules/registration/pages/child/child.module').then((m) => m.ChildPageModule)
      },
      {
        path: 'child/child-first-sign-in/introduction',
        loadChildren: () =>
          import('./modules/users/pages/child/child-first-sign-in/introduction/introduction.module').then(
            (m) => m.IntroductionPageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'games',
        component: GamePageComponent,
        loadChildren: () => import('./modules/game/pages/game-page/game-page.module').then((m) => m.GamePageModule),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'child/challenges',
        loadChildren: () => import('./modules/challenges/pages/challenges.module').then((m) => m.ChallengesModule),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'support/:type',
        loadChildren: () => import('./modules/general/support/support.module').then((m) => m.SupportPageModule),
        canActivate: [ParentalGateGuard]
      },
      {
        path: 'holding',
        loadChildren: () => import('./modules/holding/holding.module').then((m) => m.HoldingModule)
      },
      {
        path: 'child/paywall',
        loadChildren: () => import('./modules/game/pages/paywall/paywall.module').then((m) => m.PaywallPageModule)
      },
      {
        path: 'sign-in',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./modules/payments/pages/billing/billing.page.module').then((m) => m.BillingPageModule),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'billing/success',
        loadChildren: () =>
          import('./modules/payments/pages/success/success.page.module').then((m) => m.SuccessPageModule),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'child/first-sign-in/:which',
        loadChildren: () =>
          import('./modules/users/pages/child/child-first-sign-in/introduction/introduction.module').then(
            (m) => m.IntroductionPageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'child/select-character',
        loadChildren: () =>
          import('./modules/users/pages/child/child-first-sign-in/select-character/select-character.module').then(
            (m) => m.SelectCharacterPageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'parent/users/edit/:id',
        loadChildren: () =>
          import('./modules/users/pages/parent/parent-edit-user/parent-edit-user.module').then(
            (m) => m.ParentEditUserModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'teacher/upgrade',
        loadChildren: () =>
          import('./modules/users/pages/teacher/teacher-upgrade/teacher-upgrade.module').then(
            (m) => m.TeacherUpgradePageModule
          ),
        canActivate: [UserUnauthenticatedGuard]
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule)
      },
      {
        path: 'teacher',
        component: TeacherLayoutComponent,
        loadChildren: () => import('./teacher/teacher.module').then((m) => m.TeacherModule)
      },
      {
        path: 'sso/wonde',
        loadChildren: () => import('./modules/auth/pages/wonde/wonde.page.module').then((m) => m.WondePageModule)
      }
    ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
