import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { QuestionsService } from '../../services/questions.service';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { HttpWrapperService } from '../../services/http-wrapper.service';
import { Constants } from 'src/app/util/constants';
import { State } from '../../../auth/models/auth.types';
import { Words, Question } from '../../interfaces/questions.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { PopUpPage } from 'src/app/modules/common/modals/pop-up/pop-up.page';

@Component({
  selector: 'app-que-anagram',
  templateUrl: './que-anagram.component.html',
  styleUrls: ['./que-anagram.component.scss']
})
export class QueAnagramComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() question: Question = null;
  @Output() answerEvent = new EventEmitter();
  @Output() processAnswerEvent = new EventEmitter();

  // state: State;
  subs = new Subscription();

  state: State;
  answers: Words[] = [];
  correct: any = 'unset';
  isAnswerSend = false;
  isAnswerValid = false;
  correctAnswerID: string[];
  anagram: Words[];

  constructor(
    private readonly questions: QuestionsService,
    private readonly router: Router,
    private readonly loading: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly authStateService: AuthStateService,
    private readonly audio: AudioService,
    public http: HttpWrapperService
  ) {}

  ngOnInit() {
    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
    this.setup();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.ionViewDidEnter();
    }, 10);
  }

  ionViewDidEnter() {
    this.setup();
  }

  setup() {
    this.correct = 'unset';
    // eslint-disable-next-line id-blacklist
    this.answers = [];
    this.question.question = this.question.question.replace('[type]', '<span>' + this.question.type + '</span>');
    this.question.construct = this.question.construct.replace(
      '[selection]',
      '&nbsp;<strong>' + this.question.focus + '</strong>'
    );
    const letters = Array.from(this.question.focus);
    let index = 0;
    this.anagram = [];
    letters.forEach((letter) => {
      const word: Words = {
        id: index,
        word: letter,
        points: 0,
        selection_number: 0
      };
      this.anagram.push(word);
      index++;
    });
  }

  selectLetter(word) {
    if (!this.isSelected(word)) {
      this.answers.push(word);
      this.isAnswerSend = false;
      this.audio.play('click');
    }
  }

  deleteLetter() {
    this.answers.pop();
    this.isAnswerSend = false;
    this.audio.play('click');
  }

  checkAnswer() {
    this.isAnswerSend = true;

    const answer = this.getAnswer();
    if (answer !== this.question.words[0].word) {
      this.isAnswerValid = false;
    } else {
      this.isAnswerValid = true;
    }

    this.answerEvent.emit(this.getAnswer());
    this.processAnswerEvent.emit();

    return;
  }

  isSelected(word: Words) {
    const existingWord = this.answers.find((w) => w.id === word.id);
    if (existingWord != null) {
      return true;
    }
    return false;
  }

  getAnswer() {
    let answer = '';
    this.answers.forEach((letter) => {
      answer += letter.word;
    });

    return answer;
  }

  exitGame() {
    if (this.state.user.first) {
      this.authStateService.logout();
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }

  exitImpersonatedParent() {
    if (this.state.user.first) {
      this.authStateService.signOutImpersonate(this.state);
    } else {
      this.router.navigate(['child/dashboard']);
    }
  }

  async nextQuestion() {
    const answered = this.answers.length;
    const points = 10;
    let answerRevealTime = Constants.delayTime.correctAnswerDelay;

    if (answered > 0) {
      const response = await this.questions.answerQuestion(points);

      this.correct = response.correct;

      // Play sounds
      if (this.correct) {
        this.audio.play('correct');
      } else {
        this.audio.play('incorrect');
        answerRevealTime = Constants.delayTime.inCorrectAnswerDelay;
      }

      // Get all the correct words (id) and assign correctWord css class
      if (!this.correct) {
        for (const correctID of this.correctAnswerID) {
          if (correctID) {
            const element = document.getElementById(correctID);
            element.className = 'correctWord';
          }
        }
      }

      setTimeout(() => {}, answerRevealTime); // 2 seconds
    } else {
      const err = await this.modalCtrl.create({
        component: PopUpPage,
        showBackdrop: false,
        componentProps: {
          title: 'Notice',
          text: 'Please select an answer'
        }
      });

      await err.present();
    }
  }
}
