import { Component, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { filter, map } from 'rxjs/operators';
import { ModalController, Platform, ToastController, ToastOptions } from '@ionic/angular';
import { AuthStateService } from './modules/auth/services/auth.service';
import { ExitQuestionsPage } from './modules/common/modals/exit-questions/exit-questions.page';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseService } from './modules/common/services/analytics/firebase.service';
import { UserHelper } from './util/userHelper';
import { User } from './modules/auth/models/auth.types';
import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { PaymentsService } from './modules/payments/services/payments.service';
import { OrientationLockService } from './modules/common/services/orientation-lock/orientation-lock.service';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { FeatureService } from './modules/common/services/features/features.service';
import { DemoService } from './teacher/services/demo.service';

interface iPage {
  title: string;
  url: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('app-parent-upgrade') parentUpgrade: any;
  public appPages: iPage[] = [];
  user: User;
  portraitOverlayTriggered: boolean;

  constructor(
    private readonly storage: Storage,
    private readonly titleService: Title,
    private readonly authStateService: AuthStateService,
    private readonly modalCtrl: ModalController,
    private readonly router: Router,
    private readonly firebaseService: FirebaseService,
    private readonly payments: PaymentsService,
    private readonly orientation: OrientationLockService,
    private readonly toast: ToastController,
    private features: FeatureService,
    private readonly demo: DemoService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle(environment.title);
    this.initializeApp();
  }

  initializeApp() {
    this.features.init();
    this.demo.init();

    if (Capacitor.isNativePlatform()) {
      StatusBar.hide();

      CapacitorUpdater.notifyAppReady();

      CapacitorUpdater.addListener('downloadComplete', () => {
        if (!this.features.enabled('update_notification')) {
          return;
        }

        const meta = this.features.meta('update_notification');

        const notify = async () => {
          const toastOptions: ToastOptions = {
            header: meta.header as string,
            message: meta.message as string,
            icon: meta.icon as string,
            position: 'bottom'
          };

          if (!meta.force as boolean) {
            toastOptions.buttons = [
              {
                icon: 'close',
                role: 'cancel',
                handler: () => {
                  this.toast.dismiss();
                }
              }
            ];
          }

          const toast = await this.toast.create(toastOptions);
          toast.present();
        };

        notify();
      });
    }

    this.storage.create().then();

    this.firebaseService.setup();

    this.authStateService.state.subscribe((state) => {
      if (state?.user) {
        this.orientation.setLandscape();
      } else {
        this.orientation.unlock();
      }
    });

    this.authStateService.state
      .pipe(
        filter((state) => state !== null),
        map((state) => state.user)
      )
      .subscribe((user) => {
        if (!user) {
          return;
        }

        this.appPages = environment.appId === '1' ? this.getPrimaryPages(user) : this.getElevenPlusPages();

        this.user = user;

        this.payments.setup();

        if (Capacitor.isNativePlatform()) {
          SplashScreen.hide();
        }
      });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        console.log(`Navigated to ${event.url}`);
        console.log(event.id);

        if (Capacitor.isNativePlatform()) {
          this.firebaseService.trackScreen(event.url);
        }
      }
    });
  }

  async showSignOut() {
    const exit = await this.modalCtrl.create({
      component: ExitQuestionsPage,
      showBackdrop: false
    });

    await exit.present();

    const leave = await exit.onWillDismiss();

    if (leave.data) {
      this.authStateService.logout();
    }
  }

  getUrl = () => `${this.user.type}/users/profile`;

  private getUserType(user: User): string {
    let userType;

    if (user.type === 'parent' || user.type === 'tutor') {
      userType = 'parent';
    } else {
      userType = user.type;
    }

    return userType;
  }

  private getPrimaryPages(user: User): iPage[] {
    return [
      { title: 'Home', url: '/' + UserHelper.getHomeUrl(user) },
      { title: 'Users', url: '/' + this.getUserType(user) + '/users' },
      { title: 'Account', url: '/' + this.getUserType(user) + '/settings' },
      { title: 'Support', url: 'support/0' }
    ];
  }

  private getElevenPlusPages(): iPage[] {
    return [
      { title: 'Home', url: '/child/dashboard' },
      { title: 'Account', url: '/parent/settings' },
      { title: 'Support', url: 'support/0' }
    ];
  }
}
