import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-target-brick',
  templateUrl: './target-brick.component.html',
  styleUrls: ['./target-brick.component.scss']
})
export class TargetBrickComponent implements OnInit {
  @Input() word: string;
  @Input() index: number;

  appId: string;

  constructor() {}

  ngOnInit() {
    this.appId = environment.appId;
  }
}
