import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-word-brick',
  templateUrl: './word-brick.component.html',
  styleUrls: ['./word-brick.component.scss']
})
export class WordBrickComponent implements OnInit {
  @Input() word: string;
  appId: string;

  constructor() {}

  ngOnInit() {
    this.appId = environment.appId;
  }
}
