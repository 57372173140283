import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrivateApiService } from 'src/app/modules/api/private-api.service';
import { DemoService } from './demo.service';

export interface Group {
  id: number;
  name: string;
  wonde_enabled: boolean;
  learners_count: number;
}

@Injectable({ providedIn: 'root' })
export class LearnersService {
  groups$: BehaviorSubject<Group[]> = new BehaviorSubject([]);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  learners$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  paginator$: BehaviorSubject<any> = new BehaviorSubject({});
  groupIds$: BehaviorSubject<number[]> = new BehaviorSubject([]);

  private busy = false;
  private page = 1;
  private perPage = 50;
  private query: string;

  constructor(private readonly api: PrivateApiService, private readonly demo: DemoService) {
    this.init();
  }

  setGroups(groups: number[]): void {
    console.log(`LearnersService: Set ${groups.length} groups`);
    this.groupIds$.next(groups);
    this.page = 1;
    this.fetch();
  }

  setPage(page: number): void {
    console.log(`LearnersService: Setting page ${page}`);

    this.page = page;
    this.fetch();
  }

  setPerPage(count: string): void {
    console.log(`LearnersService: Setting perPage ${count}`);
    const parsed = parseInt(count, 10);

    this.page = 1;

    if (this.perPage === parsed) {
      return;
    }

    this.perPage = parsed;

    this.fetch();
  }

  setQuery(query: string): void {
    console.log(`LearnersService: Setting query ${query}`);

    this.page = 1;
    this.query = query;
    this.fetch();
  }

  public async fetch(): Promise<void> {
    if (this.busy) {
      console.log('LearnersService: fetch already in progress');
      return;
    }

    console.log('LearnersService: fetch');
    this.busy = true;
    this.loading$.next(true);

    await this.api.getGroups().subscribe((response) => {
      this.groups$.next(response.data);
    });

    let query = `?per_page=${this.perPage}&page=${this.page}&include=groups`;

    if (this.groupIds$.value) {
      query += `&filter[groups]=${this.groupIds$.value}`;
    }

    if (this.query) {
      query += `&filter[search]=${this.query}`;
    }

    await this.api.getLearners(query).subscribe((response) => {
      this.learners$.next(response.data);
      this.paginator$.next(response.meta);

      this.busy = false;
      this.loading$.next(false);
    });
  }

  private init(): void {
    console.log('LearnersService: Init');
    this.loading$.next(true);

    this.fetch();

    this.demo.active$.subscribe(() => this.fetch());
  }

  private getGroupIdsForQuery(): number[] | null {
    const ids = this.groupIds$.value;

    if (ids.length < 1) {
      return null;
    }

    return ids;
  }
}
