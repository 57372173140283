import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageKeys, StorageService } from 'src/app/modules/common/services/storage/storage.service';

@Injectable({ providedIn: 'root' })
export class DemoService {
  public readonly active$: Observable<boolean>;
  private activeSubject$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private readonly storage: StorageService) {
    this.active$ = this.activeSubject$.asObservable();
  }

  activate(): void {
    this.activeSubject$.next(true);
  }

  deactivate(): void {
    this.activeSubject$.next(false);
  }

  init(): void {
    console.log('DemoService: Initialising');

    this.storage.get(StorageKeys.demoMode).then((active: boolean | null) => {
      this.activeSubject$.next(active === null ? true : active);

      this.active$.subscribe((next) => {
        this.storage.set(StorageKeys.demoMode, next);

        console.log(`DemoService: demo mode is ${next ? 'active' : 'inactive'}`);
      });
    });
  }
}
