import stripeTestElevenPlus from './stripe.test.eleven-plus';
import bugsnag from './bugsnag';
import firebaseElevenPlus from './firebase.eleven-plus';
import revenuecatElevenPlusStaging from './revenuecat.eleven-plus.staging';

export const environment = {
  maintenance: false,
  production: true,
  title: 'ChatterStars 11+',
  name: 'staging',
  baseUrl: 'https://api.staging.chatterstars.co.uk/',
  appUrl: 'https://plususer.chatterstars.co.uk/',
  appId: '2',
  settings: {
    disableLoginChecks: false,
    enableMockData: false
  },
  hubspot: {
    enabled: true
  },
  stripe: stripeTestElevenPlus,
  firebase: firebaseElevenPlus,
  revenuecat: revenuecatElevenPlusStaging,
  bugsnag
};
