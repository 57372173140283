<ion-content [scrollY]="false" class="content-bg-color">
  <div class="center-all column sortt">

    <p class="instruction" [innerHTML]="question.question"></p>

    <ion-grid>
      <ion-row>
        <ion-col>

          <div class="question-image">
            <img [src]="(question.image_url) ? question.image_url : '/assets/account-icons/tree.svg'">
          </div>

          <p class="focus-word">"{{ question.focus }}"</p>

        </ion-col>
        <ion-col>

          <div [dragula]="'words'" [(dragulaModel)]="available_words" class="word-list unselected">

            <div *ngFor="let word of available_words; index as i" class="wl-item">
              <div class="word">{{ word ? word.word : '' }}</div>
            </div>

            <div *ngIf="!correct">
              <div *ngFor="let co of correctOrder" class="wl-item">
                <div class="word correctword">{{ co ? co : '' }}</div>
              </div>
            </div>

          </div>

        </ion-col>
        <ion-col>

          <div [dragula]="'answers'" [(dragulaModel)]="answers" class="word-list selected">

            <div *ngFor="let answer of answers; index as i" class="wl-item" [class.filled]="answers[i][0]">
              <div class="word target-{{i}}" id="word target-{{i}}" [dragula]="'words'" [(dragulaModel)]="answers[i]">{{ answer[0] ? answer[0].word : '' }}</div>
            </div>

          </div>

        </ion-col>
      </ion-row>
      <div class="strength-scale">
        <div>Strongest</div>
        <img src="/assets/misc-icons/strength-range.svg">
        <div>Weakest</div>
      </div>
    </ion-grid>

  </div>
</ion-content>
