import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  enabled = false;

  constructor(private platform: Platform) {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  logEvent(name: string, params: object) {
    if (!this.enabled) {
      return;
    }

    this.platform.ready().then(() => {
      FirebaseAnalytics.logEvent({ name, params });
    });
  }

  async setup() {
    if (this.platform.is('capacitor')) {
      this.enabled = true;

      return;
    }

    if (!environment.firebase) {
      return;
    }

    await FirebaseAnalytics.initializeFirebase(environment.firebase);

    this.enabled = true;
  }

  trackScreen(screenName: string) {
    if (!this.enabled) {
      return;
    }

    this.platform.ready().then(() => {
      FirebaseAnalytics.setScreenName({ screenName });
    });
  }
}
