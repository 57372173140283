import { Injectable } from '@angular/core';
import { Howl } from 'howler';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  enabled = false;

  private sounds = {
    click: new Howl({ src: '../../../assets/audio-files/generic-button.mp3' }),
    complete: new Howl({ src: '../../../assets/audio-files/q-set-complete.mp3' }),
    correct: new Howl({ src: '../../../assets/audio-files/item-milestone.mp3' }),
    incorrect: new Howl({ src: '../../../assets/audio-files/q-correct.mp3' }),
    star: new Howl({ src: '../../../assets/audio-files/star-awarded.mp3' })
  };

  constructor(private readonly storage: Storage) {
    this.storage.create();
    this.storage.get('enableSound').then((enabled) => (this.enabled = enabled ?? true));
  }

  play(key: string): void {
    if (this.enabled && this.sounds.hasOwnProperty(key)) {
      this.sounds[key].play();
    }
  }

  enable(): void {
    this.enabled = true;
    this.storage.set('enableSound', this.enabled);
  }

  disable(): void {
    this.enabled = false;
    this.storage.set('enableSound', this.enabled);
  }
}
