import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PrivateApiService } from '../../api/private-api.service';
import { AuthStateService } from '../../auth/services/auth.service';
import { Purchases, PurchasesOfferings } from '@awesome-cordova-plugins/purchases/ngx';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from '../models/payments.models';
import { addSeconds, differenceInDays, isAfter, parseISO } from 'date-fns';
import { EnvironmentService, RevenueCatConfig } from '../../common/services/environment/environment.service';

@Injectable({ providedIn: 'root' })
export class PaymentsService {
  subscription$ = new BehaviorSubject<Subscription | null>(null);
  config: RevenueCatConfig;
  private configured = false;
  private expires: Date;
  private id: string;

  constructor(
    private readonly platform: Platform,
    private readonly auth: AuthStateService,
    private readonly api: PrivateApiService,
    private purchases: Purchases,
    private environment: EnvironmentService
  ) {
    this.auth.state.subscribe((state) => {
      this.id = state?.subscription?.subscriber_id;
      this.config = this.environment.getPaymentsConfiguration(state?.user);

      if (this.configured && this.id) {
        console.log('PaymentsService: Logging in');
        this.purchases.logIn(this.id);

        return;
      }

      if (!this.configured && this.id) {
        console.log('PaymentsService: Booting');
        this.update();

        return;
      }

      if (this.configured && !this.id) {
        console.log('PaymentsService: Logging out');
        this.purchases.logOut();
        this.id = null;
        this.configured = false;
        this.expires = null;
        this.subscription$.next(null);
      }
    });
  }

  async update(force: boolean = false): Promise<Subscription> {
    if (!this.configured && this.id) {
      this.setup();

      return;
    }

    console.log('PaymentService: Updating subscription status');

    if (force) {
      console.log('PaymentService: Invalidating cache');
    }

    if (!this.isNative() || force) {
      console.log('PaymentService: Fetching web subscription');

      const now = new Date();

      if (force) {
        this.expires = null;
      }

      if (this.expires && this.subscription$.value && isAfter(this.expires, now)) {
        console.log('PaymentService: Returning cached subscription');
        return this.subscription$.value;
      }

      console.log('PaymentService: Caching subscription');
      this.expires = addSeconds(new Date(), 300);

      const subscription = await this.fetchWebSubscription();

      this.subscription$.next(subscription);

      return subscription;
    } else {
      console.log('PaymentService: Fetching native subscription');

      const subscription = await this.fetchNativeSubscription();

      this.subscription$.next(subscription);

      return subscription;
    }
  }

  async offerings(): Promise<PurchasesOfferings> {
    console.log('PaymentService: Retrieving offerings');

    return this.isNative() ? this.purchases.getOfferings() : (await this.api.getOfferings().toPromise()).payload;
  }

  setup() {
    this.platform.ready().then(async () => {
      if (this.configured) {
        return;
      }

      console.log(`PaymentService: Platform ${this.isNative() ? 'native' : 'web'} is ready`);

      if (this.isNative()) {
        this.purchases.setDebugLogsEnabled(true);

        if (this.platform.is('ios')) {
          console.log(`PaymentService: Purchases.setup('ios', '${this.id}')`);

          this.purchases.setup(this.config.keys.ios, this.id);
        } else if (this.platform.is('android')) {
          console.log(`PaymentService: Purchases.setup('android', '${this.id}')`);

          this.purchases.setup(this.config.keys.android, this.id);
        }
      }

      console.log(`PaymentService: Configured with user ${this.id}`);
      this.configured = true;
      this.update();
    });
  }

  private isNative() {
    return Capacitor.getPlatform() !== 'web';
  }

  private async fetchNativeSubscription(): Promise<Subscription> {
    console.log('PaymentService: Fetching subscription status from RevenueCat');
    const purchaser = await this.purchases.getPurchaserInfo();

    console.log(purchaser);

    const entitlement = purchaser.entitlements.active?.default;

    const start = entitlement && entitlement.latestPurchaseDate ? parseISO(entitlement.latestPurchaseDate) : null;
    const end = entitlement && entitlement.expirationDate ? parseISO(entitlement.expirationDate) : null;

    const subscription = {
      subscriber_id: purchaser.originalAppUserId,
      subscribed: !!entitlement,
      source: this.source(entitlement?.store),
      is_trial: entitlement && entitlement.store.toLowerCase() === 'promotional',
      starts_at: entitlement?.latestPurchaseDate ?? null,
      ends_at: entitlement?.expirationDate ?? null,
      duration: start && end ? differenceInDays(end, start) : null,
      remaining: end ? differenceInDays(end, new Date()) : null,
      trial_eligible: true
    };

    console.log(subscription);

    return subscription;
  }

  private async fetchWebSubscription(): Promise<Subscription> {
    console.log('PaymentService: Fetching subscription status from API');
    try {
      return (await this.api.getSubscription().toPromise()).payload;
    } catch {
      this.auth.logout();
    }
  }

  private source(source: string = null): string {
    switch (source) {
      case 'app_store':
      case 'mac_app_store':
      case 'play_store':
      case 'amazon':
        return 'iap';
      case 'stripe':
        return 'web';
      case 'promotional':
        return 'trial';
      default:
        return 'unknown';
    }
  }
}
