<div class="page app-light-bg">
  <div class="page-header">
    <h1>Unscramble the word to finish the sentence!</h1>
  </div>

  <div class="page-layout">
    <div class="letter-container">

      <div class="letter-grid">
        <p class="letter"
           *ngFor="let letter of anagram"
           id="letter_{{letter.id}}"
           [class.selected]="isSelected(letter)"
           (click)="selectLetter(letter)">{{ letter.word }}
        </p>
      </div>

    </div>
    <div class="device">

      <div class="screen">
        <div class="main">
          <div class="question-bubble" [innerHtml]="question.construct"></div>

          <div class="answer" *ngIf="isAnswerSend" >
            <div class="answer-bubble"  [ngStyle]="{'background-color': isAnswerValid ? '#20d342' : '#eb445a'}">
              {{ question.words[0].word }}&nbsp;
            </div>
          </div>
        </div>

        <div class="button-bar">
          <div class="answer-input">
            {{ getAnswer() }}&nbsp;
          </div>

          <div class="spacer"></div>

          <div>
            <a class="button delete" (click)="deleteLetter()">
              <img src="/assets/misc-icons/delete.svg"/>
            </a>

            <a class="button" (click)="checkAnswer()">
              <img src="/assets/misc-icons/paper-plane.svg"/>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="orientation-message">
  <img src="/assets/misc-icons/rotate-device.svg"/>
  <h1>Please rotate the device.</h1>
</div>
