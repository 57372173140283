import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-exit-questions',
  templateUrl: './exit-questions.page.html',
  styleUrls: ['./exit-questions.page.scss']
})
export class ExitQuestionsPage implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  close(choice) {
    this.modalCtrl.dismiss(choice);
  }
}
