import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { State } from 'src/app/modules/auth/models/auth.types';
import { AuthStateService } from 'src/app/modules/auth/services/auth.service';
import { PopUpPage } from 'src/app/modules/common/modals/pop-up/pop-up.page';
import { AudioService } from 'src/app/modules/common/services/audio/audio.service';
import { OrientationLockService } from 'src/app/modules/common/services/orientation-lock/orientation-lock.service';
import { HttpWrapperService } from '../../services/http-wrapper.service';
import { QuestionsService } from '../../services/questions.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.page.html',
  styleUrls: ['./complete.page.scss']
})
export class CompletePage implements OnInit, OnDestroy {
  state: State;
  answers: any = [0, 0, 0, 0, 0, 0];
  user: any = {};
  sending: any = false;
  level: any = 0;
  levelUp: any = 0;

  loading = false;

  text: any = '';
  stars: any = 0;
  openModal: HTMLIonLoadingElement;

  loader: HTMLIonLoadingElement;

  // eslint-disable-next-line @typescript-eslint/ban-types
  public lottieConfig: object;
  private anim: any;
  private animationSpeed = 1;

  constructor(
    private readonly questions: QuestionsService,
    private readonly authStateService: AuthStateService,
    private readonly http: HttpWrapperService,
    private readonly router: Router,
    private readonly loadingCtrl: LoadingController,
    private readonly modalCtrl: ModalController,
    private readonly audio: AudioService
  ) {
    this.lottieConfig = {
      path: 'assets/animations/' + this.user.character + '3/data.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
    };

    this.authStateService.state.pipe(untilDestroyed(this)).subscribe((state) => (this.state = state));
  }

  ngOnInit() {
    this.state.lastResult = null;
  }

  ngOnDestroy() {}

  exit() {
    this.audio.play('click');
    this.modalCtrl.dismiss();
    this.router.navigate(['/child/dashboard'], { replaceUrl: true });
  }

  ionViewDidEnter() {
    this.sendResults();
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  async sendResults() {
    this.loader = await this.loadingCtrl.create({ message: 'Please wait...' });
    await this.loader.present();
    this.questions
      .submitAnswers()
      .then(() => {
        if (this.state.lastResult?.stars > 0) {
          this.audio.play('star');
        } else {
          this.audio.play('complete');
        }

        this.user = this.state.user;
      })
      .catch(() => {
        //
      })
      .finally(() => this.loader.dismiss());
  }

  async nextSet() {
    this.modalCtrl.dismiss();
    if (!this.questions.set) {
      const set = false;
      let attempts = 0;

      do {
        await this.questions.getSet(0);
        attempts++;
      } while (!set && attempts < 3);
      if (!set) {
        const err = await this.modalCtrl.create({
          component: PopUpPage,
          showBackdrop: false,
          componentProps: {
            title: 'Error',
            text: `There was a problem contacting the server. Please ensure you're connected to the internet and try again later.`
          }
        });
        await err.present();
      }
    }

    if (this.questions.set && !this.questions.wait) {
      const type = this.router.url.split('/')[2];
      let set = this.router.url.split('/')[3];

      if (set && !isNaN(Number(set))) {
        set = (Number(set) + 1).toString();
      } else {
        set = '1';
      }
      this.router.navigate([`/child/game-page/${set}`], { replaceUrl: true });
    } else if (this.questions.wait) {
      this.router.navigate(['/child/paywall'], { replaceUrl: true });
    }
  }

  async toggleLoading(toggle) {
    if (!this.loading && toggle) {
      this.openModal = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      this.loading = true;
      await this.openModal.present();
    } else if (this.loading && !toggle) {
      this.openModal.dismiss();
      this.loading = false;
    }
  }
}
