import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parental-gate',
  templateUrl: './parental-gate.component.html',
  styleUrls: ['./parental-gate.component.scss']
})
export class ParentalGateComponent implements OnInit {
  a: number;
  b: number;
  operatorString: string;
  correctAnswer: number;
  incorrectAnswerA: number;
  incorrectAnswerB: number;
  shuffledAnswers: number[];
  type: string;

  constructor(private modalCtrl: ModalController, private router: Router) {}

  ngOnInit(): void {
    this.a = this.randomInt(1, 100);
    this.b = this.randomInt(1, 100);

    if (Math.random() < 0.5) {
      this.addition();
    } else {
      this.subtraction();
    }

    this.type = '0';

    if (this.router.url.indexOf('parent/upgrade') > 0) {
      this.type = '1';
    }
  }

  submit(answer) {
    this.modalCtrl.dismiss(answer === this.correctAnswer);
  }

  private randomInt(min, max): number {
    return Math.floor(Math.random() * max) + min;
  }

  private addition() {
    this.correctAnswer = this.a + this.b;
    this.incorrectAnswerA = this.correctAnswer + this.randomInt(1, 20);
    this.incorrectAnswerB = this.correctAnswer + this.randomInt(1, 20);
    this.operatorString = '+';
    this.shuffleAnswers();
  }

  private subtraction() {
    this.correctAnswer = this.a - this.b;
    this.incorrectAnswerA = this.correctAnswer - this.randomInt(1, 20);
    this.incorrectAnswerB = this.correctAnswer - this.randomInt(1, 20);
    this.operatorString = '-';
    this.shuffleAnswers();
  }

  private shuffleAnswers() {
    this.shuffledAnswers = [this.correctAnswer, this.incorrectAnswerA, this.incorrectAnswerB]
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }
}
